<template>
    <div>
      <b-card class="screenAlignment" >
        <!-- <b-card-title>
          <div class="d-flex align-items-left justify-content-between">
            <h4 class="card-title">{{ chartData.title }}</h4>
             <div>
              <b-button @click="deleteGraph" v-b-tooltip.hover.bottom="'Delete Graph'" variant="outline-danger"
                class="btn-icon rounded-circle">
                <feather-icon icon="XIcon" />
              </b-button>
            </div>
          </div>
        </b-card-title> -->
      <div>
        <AmazonApexChart v-if="labels.length" :labels="labels" :series="series" />
      </div>
      </b-card>
    </div>
  </template>
  
  <script>
  import { BCard, BCardTitle, BButton, VBTooltip } from "bootstrap-vue";
  import AmazonApexChart from "./AmazonApexChart.vue";
  
  export default {
    props: {
      index: {
        type: Number,
        default: 0,
        required: true,
      },
      chartData: {
        type: Object,
        default: () => {
          return {
            title: "",
            metric1: "",
            metric2: "",
          };
        },
      },
      data: {
        type: Array,
        default: () => {
          return [];
        },
      },
    },
    components: {
      BCard,
      AmazonApexChart,
      BCardTitle,
      BButton,
    },
    directives: {
      "b-tooltip": VBTooltip,
    },
    data() {
      return {
        labels: [],
        series: [],
        metricMap: {
          acos:"ACOS",
          clicks: "Clicks",
          conversionRate: "CVR",
          costPerClick: "Cost Per Click",
          ctr: "CTR",
          cvr: "CVR",
          orders:"Orders",
          impressions: "Impressions",
          key: "Date",
          purchases30d:"Purchases 30 days",
          roas: "ROAS",
          sales:"Sales",
          spends:"Spends",
          aov:"AOV",
        }
      };
    },
    methods: {
      generateLabelsAndSeries() {
        let data = this.data;
        let chartData = this.chartData;
        let labels = [];
        let series = [
          { name: this.mappedMetric1, data: [] },
          { name: this.mappedMetric2, data: [] },
        ];
        for (let i = 0; i < data.length; i++) {
        const campaignData = data[i];
        labels.push(campaignData.key);
        series[0].data.push(
          parseFloat(campaignData[chartData.metric1].toFixed(2))
        );
        series[1].data.push(
          parseFloat(campaignData[chartData.metric2].toFixed(2))
        );
      }
        this.labels = labels;
        this.series = series;
      },
      deleteGraph() {
        if (
          confirm(
            `Do you want to remove the selected Graph - ${this.chartData.title}?`
          )
        ) {
          this.$emit("deleteGraph", this.index);
        }
      },
    },
    computed: {
      mappedMetric1() {
        return this.metricMap[this.chartData.metric1] || "";
      },
      mappedMetric2() {
        return this.metricMap[this.chartData.metric2] || "";
      },
    },
    mounted() {
      this.generateLabelsAndSeries();
    },
  };
  </script>
  
  <style  lang="scss">
.screenAlignment{
  .card-body {
    padding: 0.2rem !important;
}
}
</style>