<template>
    <div>
      <b-card class="screenAlignmentDataTable">
        <div>
          <div class="schedule-hearder">
            <div>
              <b-card-title>
              <h3 class="app-title mdsm">{{ title }}</h3>
            </b-card-title>
            </div>
            <div style="display: flex; justify-content: space-between;gap: 10px;">
              <div style="display: flex; justify-content: space-between;gap: 10px;">
                <div style="width: 150px;">
                  <b-form-group>
                    <label for="client-name">Sub Category Type:</label>
                      <b-form-select  @input="getSubCatergoryChange"  class="uppercase-options" v-model="selectedSubCategoriesType" :options="this.subCampaign && this.subCampaign.sub_category"/>
                   </b-form-group>
                </div>
                <div style="width: 170px;">
                  <b-form-group>
                    <label for="client-name">Sub Sub Category Type:</label>
                      <b-form-select  @input="getSubSubCatergoryChange"  class="uppercase-options" v-model="selectedSubSubCategoriesType" :options="this.subCampaign && this.subCampaign.sub_sub_category"/>
                   </b-form-group>
                </div>
                <div style="width: 130px;">
                  <b-form-group>
                    <label for="client-name">BCG:</label>
                      <b-form-select @input="getBCGCatergoryChange"   class="uppercase-options" v-model="selectedBcg" :options="this.subCampaign && this.subCampaign.bcg"/>
                   </b-form-group>
                </div>
                <div style="width: 130px;">
                  <b-form-group>
                    <label for="client-name">Ad Type:</label>
                      <b-form-select  @input="getAdTypeCatergoryChange"   class="uppercase-options" v-model="selectedAdType" :options="this.subCampaign && this.subCampaign.ad_type"/>
                   </b-form-group>
                </div>
              </div>
              <!-- <input
              type="text"
              style="width: 300px; margin-top:23px"
              v-model="searchTerm"
              placeholder="Campaign name.."
            /> -->
              <b-button  @click="exportData" class="exportButton"  size="sm" variant="primary" style=" margin-top:23px"
              ><span style="font-weight: bold;">
                Export Data
                <feather-icon
                  icon="DownloadIcon"
                  size="15"
                  style="margin-left: 5px;"/></span
            ></b-button>
            </div>
          </div>
        </div>
        <div v-if="this.isDataLoadingForSub" class="spinnerCentered">
          <multiChannelSpinner />
        </div>
        <div v-else>
          <vue-good-table
          class="custom-data-table mainTable"
          :columns="tableColumns"
          :rows="chartData"
          :search-options="{
            enabled: true,
            externalQuery: searchTerm
          }"
          :select-options="{
            enabled: false,
            selectOnCheckboxOnly: true,
            selectionInfoClass: 'custom-class',
            selectionText: 'rows selected',
            clearSelectionText: 'clear',
            disableSelectInfo: true,
            selectAllByGroup: true
          }"
          :pagination-options="{
            enabled: true,
            perPage: pageLength
          }"
        >
          <!-- pagination -->
          <template slot="pagination-bottom" slot-scope="props">
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap"> Showing 1 to </span>
                <b-form-select
                  v-model="pageLength"
                  :options="['3', '5', '7', '10']"
                  class="mx-1"
                  @input="
                    value => props.perPageChanged({ currentPerPage: value })
                  "
                />
                <span class="text-nowrap"> of {{ props.total }} entries </span>
              </div>
              <div>
                <b-pagination
                  v-model="currentPage"
                  :total-rows="props.total"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @input="
                    currentPage => props.pageChanged({ currentPage: currentPage })
                  "
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
        </div>
      
      </b-card>
    </div>
  </template>
  
  <script>
  import {
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BCard,
    BCardTitle,
    BButton,
  } from "bootstrap-vue";
  import multiChannelSpinner from "@/components/client/multiChannelSpinner.vue";
  import { VueGoodTable } from "vue-good-table";
  import commaNumber from "comma-number";
  import millify from "millify";
  import { sortFuntionStringintoNumericVal, sortFuntionNumericVal } from "@/components/client/SortingFunction.js";
  
  export default {
    components: {
      VueGoodTable,
      BAvatar,
      BBadge,
      BPagination,
      BFormGroup,
      BFormInput,
      BFormSelect,
      BDropdown,
      BDropdownItem,
      BCard,
      BCardTitle,
      BButton,
      multiChannelSpinner
    },
    props: {
      chartData: {
        type: Array,
        default: () => {
          return [];
        }
      },
      businessType:{
        type: String
      },
      type: {
        type: String,
        default: "date"
      },
      isDataLoadingForSub:{
        type:Boolean
      },
      title: {
        type: String
      },
      exportsType:{
        type: String
      },
      subCampaign:{
        type:Object
      }
    },
    data() {
      return {
        selectedSubCategoriesType:this.subCampaign.sub_category[0],
        selectedSubSubCategoriesType:this.subCampaign.sub_sub_category[0],
        selectedBcg:this.subCampaign.bcg[0],
        selectedAdType:this.subCampaign.ad_type[0],
        pageLength: 7,
        searchTerm: "",
        currentPage: 1
      };
    },
    watch: {
      chartData(newVal, oldVal) {
        // this.chartData = newVal
        this.currentPage = 1;
      }
    },
    computed: {
      tableColumns() {
          let columns = [
            {
              label: "Campaign Name",
              field: "campaignName",
              thClass: "campaign-name-th-class",
              tdClass: "text-center break-text",
              thClass: "text-center",
              width: "180px"
            },
            {
              label: "Impressions",
              field: "impressions",
              sortFn: this.sortFn,
              tdClass: "text-center",
              thClass: "text-center"
            },
            {
              label: "Clicks",
              field: "clicks",
              sortFn: this.sortFn,
              tdClass: "text-center",
              thClass: "text-center"
            },
            {
              label: "Spends",
              field: "spends",
              sortFn: this.sortFn,
              tdClass: "text-center",
              thClass: "text-center"
            },
            {
              label: "Sales",
              field: "sales",
              sortFn: this.sortFn,
              tdClass: "text-center",
              thClass: "text-center"
            },
            {
              label: "Orders",
              field: "orders",
              sortFn: this.sortFn,
              tdClass: "text-center",
              thClass: "text-center"
            },
            {
              label: "CTR",
              field: "ctr",
              sortFn: this.sortFn,
              tdClass: "text-center",
              thClass: "text-center"
            },
            {
              label: "CPC",
              field: "costPerClick",
              sortFn: this.sortFn,
              tdClass: "text-center",
              thClass: "text-center"
            },
            {
              label: "CVR",
              field: "conversionRate",
              sortFn: this.sortFn,
              tdClass: "text-center",
              thClass: "text-center"
            },
            {
              label: "ACOS",
              field: "acos",
              sortFn: this.sortFn,
              tdClass: "text-center",
              thClass: "text-center"
            },
            {
              label: "ROAS",
              field: "roas",
              sortFn: this.sortFn,
              tdClass: "text-center",
              thClass: "text-center"
            },
            {
              label: "AOV",
              field: "aov",
              sortFn: this.sortFn,
              tdClass: "text-center",
              thClass: "text-center"
            }
          ];
          return columns;
        }
       
    },
    methods: {
      getSubCatergoryChange(val){
        this.$emit("subCatergoryChange", val);
      },
      getSubSubCatergoryChange(val){
        this.$emit("subSubCatergoryChange", val);
      },
      getBCGCatergoryChange(val){
        this.$emit("bCGCatergoryChange", val);
      },
      getAdTypeCatergoryChange(val){
        this.$emit("subAdTypeCatergoryChange", val);
      },
      getFormattedText(text, field) {
        if (field === "clicks") {
          return millify(text);
        } else if (field === "impressions") {
          return millify(text);
        } else if (field === "costMicros") {
          return "₹ " + millify(text);
        } else if (field === "conversions") {
          return millify(text.toFixed(2));
        } else if (field === "ctr") {
          return (text * 100).toFixed(2) + "%";
        } else if (field === "avg cpm" || field === "avg_cpm") {
          return "₹ " + (text * 1000).toFixed(2);
        } else if (field === "avg cpc" || field === "avg_cpc") {
          return "₹ " + text.toFixed(2);
        } else if (field === "cpc") {
          return text.toFixed(2);
        } else {
          return text;
        }
      },
  //      includeDateIntoData(array) {
  //       return array.map(item => {
  //         const { formattedData, ...rest } = item;
  //         return {
  //             date: item.date,
  //             ...formattedData
  //         };
  //     });
  // },
  exportData() {
    // Determine the columns to display based on the channel type
    let displayArr = {}
   
    if (this.exportsType === 'amazonCampaign') {
      displayArr = displayArr = {
        campaignName: "Campaign Name",
        impressions: "Impressions",
        clicks: "Clicks",
        spends:"Spends",
        sales:"Sales",
        orders:"Orders",
        ctr: "CTR",
        costPerClick: "CPC",
        conversionRate: "CVR",
        acos:"ACOS",
        roas: "ROAS",
        aov:"AOV"
      }
    }
    // Helper function to escape and quote each value for CSV
    const escapeCSV = (str) => {
      if (str === null || str === undefined) {
        return '""';
      }
      return `"${String(str).replace(/"/g, '""')}"`;
    };
  
    // Generate CSV header based on the displayArr
    const header = Object.keys(displayArr)
      .map(field => escapeCSV(displayArr[field]))
      .join(",");
  
    // Generate CSV rows based on the displayArr
    const rows = this.chartData.map(item =>
      Object.keys(displayArr)
        .map(key => escapeCSV(item[key]))
        .join(",")
    );
  
    // Combine header and rows, separated by newline
    const csv = [header].concat(rows).join("\n");
  
    // Create a Blob for the CSV
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
  
    // Create and trigger a temporary download link
    const link = document.createElement("a");
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", `${this.type}.csv`);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
  
  ,
    sortFn(a, b) {
      if (typeof a === 'string' && typeof b === 'string') {
        return sortFuntionStringintoNumericVal(a, b);
      } else {
        return sortFuntionNumericVal(a, b);
      }
    }
    },
    mounted() {
      this.currentPage = 1;
    }
  };
  </script>
  
  <style lang="scss">
  @import "@core/scss/vue/libs/vue-good-table.scss";
  
  .campaign-name-field {
    max-width: 200px;
  }
  
  .break-text {
    font-size: 12px;
    max-width: 150px;
    white-space: normal;
    overflow: visible;
  }
  
  .mainTable {
    padding: 6px !important;
  }
  .screenAlignmentDataTable {
    .card-body {
      padding: 0.6rem !important;
    }
  }
  .schedule-hearder {
    display: flex;
    padding: 0 10px;
    justify-content: space-between;
  }
  input {
    border-radius: 5px;
    padding-left: 8px;
    padding-right: 8px;
    width: 210px !important;
    height: 38px;
    background-color: white;
    border: 1px solid rgb(218, 211, 211);
    color: rgb(163, 153, 153);
  }
  .exportButton{
    height: 38px !important;
  }
  .uppercase-options .form-select-option {
    text-transform: uppercase;
  }
  .spinnerCentered {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 20px;
    /* Set the height of the container */
  }
  
  </style>
  