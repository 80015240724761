<template>
    <b-card no-body style="height: 260px;">
      <b-card-body>
        <vue-apex-charts
          type="area"
          height="275"
          :options="lineAreaChartSpline.chartOptions"
          :series="lineAreaChartSpline.series"
        />
      </b-card-body>
    </b-card>
  </template>
  
  <script>
  import {
    BCard,
    BCardHeader,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
  } from "bootstrap-vue";
  import VueApexCharts from "vue-apexcharts";
  
  export default {
    props: {
      labels: {
        type: Array,
        default: () => {
          return [];
        },
      },
      series: {
        type: Array,
        default: () => {
          return [];
        },
      },

      metricColorMap: {
        type: Object,
        default: () => { return {
            conversions: "#1f77b4",
            spends: "#2ca02c",
            impressions: "#9467bd",
            clicks: "#ff7f0e",
            sales: "#1f497d",
            roas: "#ffbb78",
            ctr: "#2ca02c",
            cvr: "#9467bd",
          }
        }
      }
    },
    components: {
      BCard,
      VueApexCharts,
      BCardHeader,
      BCardBody,
      BCardTitle,
      BCardSubTitle,
    },
    data() {
      return {
        lineAreaChartSpline: {
          series: this.series,
          chartOptions: {
            chart: {
              toolbar: {
                show: false,
              },
              type: "area",
            },
            dataLabels: {
              enabled: false,
            },
            stroke: {
              show: true,
              curve: "smooth",
              width: 2,
            },
            legend: {
              show: true,
              position: "top",
              horizontalAlign: "left",
              fontSize: "14px",
              fontFamily: "Montserrat",
            },
            grid: {
              xaxis: {
                lines: {
                  show: true,
                },
              },
            },
            xaxis: {
              categories: this.labels,
            },
            yaxis: [
              {
                title: {
                  text: this.series[0].name.toUpperCase(),
                },
              },
              {
                opposite: true,
                title: {
                  text: this.series[1].name.toUpperCase(),
                },
              },
            ],
            fill: {
              type: "gradient",
              gradient: {
                shade: "light",
                type: "vertical",
                shadeIntensity: 1,
                opacityFrom: 0.4,
                opacityTo: 0.1,
                stops: [0, 90, 100],
              },
            },
            tooltip: {
              shared: false,
            },
            
            colors: [this.metricColorMap[this.series[0].name.toLowerCase()], this.metricColorMap[this.series[1].name.toLowerCase()]],
            markers: {
              size: 4,
              hover: {
                size: 5
              }
            }
          },
        },
      };
    },
  };
  </script>
  