<template>
    <b-card no-body class="card-statistics">
      <b-card-body >
        <b-row>
          <b-col
            v-for="item in statisticsItemsData"
            :key="item.subtitle"
            class="mb-0 mb-md-0"
            :class="item.customClass"
          >
            <div class="text-center mb-1">
              <b-avatar size="48" :variant="item.color">
                <span v-if="item.icon == 'currency-rupee'" style="font-size: 22px;">
                  ₹</span>
                <span v-else><feather-icon size="24" :icon="item.icon" /></span>
              </b-avatar>
            </div>
            <b-media class="text-center" no-body>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">
                  {{ item.title }}
                </h4>
                <b-card-text class="font-small-3 mb-0">
                  {{ item.subtitle }}
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </template>
  
  <script>
  import {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BRow,
    BCol,
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,
  } from "bootstrap-vue";
  import commaNumber from "comma-number";
  import millify from "millify";
  
  export default {
    components: {
      BRow,
      BCol,
      BCard,
      BCardHeader,
      BCardTitle,
      BCardText,
      BCardBody,
      BMedia,
      BAvatar,
      BMediaAside,
      BMediaBody,
    },
    props: {
      iconType:{
        type:String
      },
      summaryData: {
        type: Object,
        default: () => {
          return {
          clicks,
          conversionValue,
          conversions,
          costPerClick,
          impressions,
          roas,
          sales,
          spends
          };
        },
      },
    },
    computed: {
      statisticsItemsData() {
        let statisticsItems = [];
let summaryData = this.summaryData;
let order = [ 'spends', 'sales','orders','impressions', 'clicks'];

for (let i = 0; i < order.length; i++) {
  const dataKey = order[i];
  let title = dataKey;
  title = title.replace("_", " ").toLowerCase();
  if (summaryData[dataKey] !== undefined) {
    let dataToPush = this.getFormattedText(summaryData[dataKey], title);
    if (dataToPush) {
      statisticsItems.push(dataToPush);
    }
  }
}

return statisticsItems;
      },
    },
    data() {
      return {};
    },
    methods: {
      getFormattedText(text, field) {
        field = field.toLowerCase();
        if (field === "clicks") {
          return {
            icon: "MousePointerIcon",
            color: "light-warning",
            title: millify(text),
            subtitle: "Clicks",
            customClass: "mb-2 mb-xl-0",
          };
        }
         else if (field === "impressions") {
          return {
            icon: "EyeIcon",
            color: "light-pink",
            title: millify(text),
            subtitle: "Impressions",
            customClass: "mb-2 mb-xl-0",
          };
        } 
        else if (field === "spends") {
          let title = ""
          if (this.iconType === "USD") {
            title = "$"
          }
          if (this.iconType === "INR") {
            title = "₹"
          }
          return {
            icon: "currency-rupee",
            color: "light-info",
            title: title+ " " + millify(text),
            subtitle: "Spends",
            customClass: "mb-2 mb-xl-0",
          };
        } 
        else if (field === "sales") {
          return {
            icon: "currency-rupee",
            color: "light-success",
            title: millify(text),
            subtitle: "Sales",
            customClass: "mb-2 mb-xl-0",
          };
        } 
        else if (field === "orders") {
          return {
            icon: "TargetIcon",
            color: "light-info",
            title: millify(text),
            subtitle: "Orders",
            customClass: "mb-2 mb-xl-0",
          };
        } 
      },
    },
  };
  </script>
  
  <style lang="scss">
.card-statistics{
  .card-body {
    padding: 1rem !important;
}
}

</style>