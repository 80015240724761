<template>
  <div class="modal_trigger" :class="{ open: show }">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content modal">
        <b-modal v-model="scheduleModal" id="main-modal-center" hide-footer hide-header centered size="small"
          no-close-on-backdrop>
          <b-card-text>
            <div class="header text-right">
              <feather-icon style="cursor: pointer" @click="closeScheduleClientPopUp" class="Xicon"
                icon="XIcon"></feather-icon>
            </div>
            <div class="modal-item">
              <div class="pr-1 pb-1 pt-0 edit-form">
                <h3 class="app-title lg">
                  Schedule Trigger
                </h3>
              </div>
              <!-- Trigger's Trigger Modal - this part of element will show from triggers page -->
              <div v-if="triggerType == 'Summary' ||
                selectedClient.triggerType == 'Summary'
                ">
                <div style="display: flex; justify-content: space-between;">
                  <div class="radio-label">
                    <input type="radio" v-model="fetchByClient" :value="true" />
                    Clients
                  </div>
                  <div class="radio-label">
                    <input type="radio" v-model="fetchByClient" :value="false" style="margin-left: -220px;" />
                    Account
                  </div>
                </div>
              </div>
              <b-row v-if="fetchByClient === false">
                <b-col cols="12"><label>Account</label>
                  <select disabled v-model="selectedAccount" style=" background-color: #efefef;
                    color: #b8c2cc;" class="   
                      client-select-conditionally   
                      ">
                    <option value="Social Beat Digital Marketing">Social Beat Digital Marketing</option>
                  </select>
                </b-col>
              </b-row>
              <b-row>
                <div class="metric-container" v-if="isTriggerModal">
                  <b-col cols="6" v-if="(isInscrease || metric) && isPercentage">
                    <div>
                      <label>Notify When
                        <span style="text-transform: uppercase; font-weight: bold">
                          {{ metric }}
                        </span>
                        <span v-if="isInscrease == '>'">increases</span>
                        <span v-if="isInscrease == '<'">drops</span> by</label>
                      <b-input-group>
                        <b-input-group-prepend is-text v-if="this.isPercentage == false &&
                          this.metric == 'spends'
                          ">
                          <span>₹</span>
                        </b-input-group-prepend>
                        <b-input-group-prepend is-text v-if="(this.isPercentage == true &&
                          this.metric != 'spends') ||
                          (this.isPercentage == true &&
                            this.metric == 'spends')
                          ">
                          <span>%</span>
                        </b-input-group-prepend>
                        <b-form-input v-model="percentage" id="metric-threshold" placeholder="Enter Percentage"
                          type="number" maxlength="3" />
                      </b-input-group>
                    </div>
                  </b-col>
                  <b-col cols="6" v-if="fetchByClient === true"><label>Client</label>
                    <select v-model="selectedClient" :class="isInscrease && isPercentage
                      ? 'client-select'
                      : 'client-select-conditionally'
                      ">
                      <option value="">Select Client</option>
                      <option v-for="onetype in fetchClientsList" :value="onetype._id" class="form-control" id="lg_type"
                        name="lg_type" v-bind:key="onetype._id">
                        {{ onetype.name }}
                      </option>
                    </select>
                  </b-col>
                </div>
                <!-- Client's Trigger Modal - this part of element will show from triggers page -->
                <div class="metric-container" v-else>
                  <b-col cols="6" v-if="selectedClient.operator && selectedClient.isPercentage
                    ">
                    <div>
                      <label style="font-size: 10.5px">Notify When
                        <span style="text-transform: uppercase; font-weight: bold">
                          {{ selectedClient.metric }}
                        </span>
                        <span v-if="selectedClient.operator == '>'">increases</span>
                        <span v-if="selectedClient.operator == '<'">drops</span>
                        by</label>
                      <b-input-group>
                        <b-input-group-prepend is-text v-if="selectedClient.isPercentage == false &&
                          selectedClient.metric == 'spends'
                          ">
                          <span>₹</span>
                        </b-input-group-prepend>
                        <b-input-group-prepend is-text v-if="(selectedClient.isPercentage == true &&
                          selectedClient.metric != 'spends') ||
                          (selectedClient.isPercentage == true &&
                            selectedClient.metric == 'spends')
                          ">
                          <span>%</span>
                        </b-input-group-prepend>

                        <b-form-input v-model="percentage" id="metric-threshold" placeholder="Enter Percentage"
                          type="number" maxlength="3" />
                      </b-input-group>
                    </div>
                  </b-col>
                  <b-col cols="5"><label style="font-size: 10.5px;">Select Trigger <span
                        class="validation">*</span></label>
                    <select v-model="selectedClient" :class="selectedClient.operator && selectedClient.isPercentage
                      ? 'client-select'
                      : 'client-select-conditionally'
                      " placeholder="select trigger">
                      <option value="" default>Select Trigger </option>
                      <option v-for="onetype in triggerData" :value="onetype" class="form-control" id="lg_type"
                        name="lg_type" v-bind:key="onetype._id">
                        {{ onetype.description }}
                      </option>
                    </select>
                  </b-col>
                </div>
              </b-row>
              <b-row v-if="fetchByClient === true">
                <b-col cols="12" class="mt-1" v-if="triggerType == 'Summary' ||
                  selectedClient.triggerType == 'Summary' ||
                  fetchByClient === true
                  "><label>Channel(s)<span class="validation">*</span></label>
                  <br />
                  <b-form-group class="client-channel-select-conditionally">
                    <v-select
                      label="name"
                      placeholder="Select Channel(s)"
                      v-model="selectedChannels"
                      :multiple="
                        triggerType == 'Summary' ||
                          selectedClient.triggerType == 'Summary'
                      "
                      :options="allChannels"
                      :clearable="
                        triggerType == 'Summary' ||
                          selectedClient.triggerType == 'Summary'
                      "
                      :deselectFromDropdown="true"
                      :class="isTriggerModal == true ? 'campaigns' : ''"
                    />
                  </b-form-group>
                  <!-- <select v-model="selectedChannel" class="client-select-conditionally">
                    <option value="">Select a Channel</option>
                    <option v-for="onetype in allChannels" :value="onetype" class="form-control" id="lg_type"
                      name="lg_type" v-bind:key="onetype">
                      {{ onetype }}
                    </option>
                  </select> -->
                </b-col>
                <b-col cols="12" class="mb-1" v-if="triggerType == 'Summary' ||
                  selectedClient.triggerType == 'Summary'
                  "><label>Campaign Type<span class="validation">*</span></label>
                  <br />
                  <select v-model="selectedCampaignType" class="client-select-conditionally">
                    <option value="">Select Campaign Type</option>
                    <option v-for="onetype in campaignType" :value="onetype" class="form-control" id="lg_type"
                      style="text-transform:capitalize" name="lg_type" v-bind:key="onetype">
                      {{ onetype }}
                    </option>
                  </select>
                </b-col>
                <b-col cols="12">
                  <label>Campaign(s)</label>
                  <b-form-group>
                    <v-select label="name" placeholder="Select Campaign(s)" v-model="selectedCampaigns" multiple
                      :loading="isCampaignsLoading" :options="campaigns"
                      :class="isTriggerModal == true ? 'campaigns' : ''" :deselectFromDropdown="true"/>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row v-if="fetchByClient === false">
                <b-col cols="12" class="mt-1"><label>Channel(s)<span class="validation">*</span></label>
                  <select disabled v-model="selectedChannel" style=" background-color: #efefef;
                    color: #b8c2cc;" class="   
                      client-select-conditionally mb-1
                      ">
                    <option value="google-ads">google-ads</option>
                  </select>
                </b-col>
              </b-row>
              <div v-if="this.selectedCampaigns.length > 1">
                <b-row>
                  <b-col cols="12">
                    <label>Campaign Data Format</label>
                    <div class="radio-container">
                      <div class="radio-label">
                        <input type="radio" v-model="getCummulativeData" :value="true" />
                        <span style="font-size: 12px">Cumulate Data </span>
                      </div>
                      <div class="radio-label" style="margin-left: 100px;">
                        <input type="radio" v-model="getCummulativeData" :value="false" />
                        <span style="font-size: 12px">Segregate Data</span>
                      </div>
                    </div>
                  </b-col>
                </b-row>
              </div>
              <div>
                <b-row>
                  <b-col cols="12">
                    <label>Email Id(s)<span class="validation">*</span></label>
                    <b-input-group :state="state">
                      <b-form-tags placeholder="Enter email id(s)" v-model="emailAddresses" input-id="tags-basic"
                        remove-on-delete :tag-validator="tagValidator" :state="state" add-button-variant="primary"
                        invalid-tag-text="Please enter a valid email address." />
                    </b-input-group>
                  </b-col>
                </b-row>
              </div>
              <b-row>
                <b-col cols="12" class="mt-1">
                  <label>SMS/WhatsApp<span class="validation">*</span></label>
                  <b-input-group :class="{
                    valid: isValidPhoneNumber,
                    invalid: !isValidPhoneNumber,
                  }">
                    <b-input-group-prepend is-text>
                      <span>+91</span>
                    </b-input-group-prepend>
                    <b-form-input v-model="contact" id="client-name" placeholder="Enter your contact number."
                      type="number"
                      oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                      maxlength="10" @keyup="validatePhoneNumber" />
                  </b-input-group>
                  <div class="invalid-warning" v-if="!isValidPhoneNumber">
                    Please enter a valid 10-digit mobile number.
                  </div>
                </b-col>
              </b-row>
              <!-- Trigger's Trigger Modal - this part of element will show from triggers page -->
              <div v-if="isTriggerModal">
                <b-row class="mt-1" :style="isComparisonMetric == false ? ' display: none' : ''"
                  v-if="isInscrease || metric">
                  <label style="margin-left: 13px">Comparison Metric<span class="validation">*</span></label>
                  <b-col cols="12">
                    <div class="info-container">
                      <span v-b-tooltip.hover class="info-label tooltip-trigger"
                        title="Compare with previous day's data at the same hour" style="margin-left: 13px">
                        <b-icon icon="info-circle-fill" />
                      </span>

                      <span v-b-tooltip.hover class="info-label info-label-right tooltip-trigger"
                        title="Compare with same day's previous hour's data">
                        <b-icon icon="info-circle-fill" />
                      </span>
                    </div>
                    <div class="radio-container">
                      <div class="radio-label">
                        <input type="radio" v-model="comparisonMetric" value="day" />
                        <span style="font-size: 12px">Previous day (Same hour)
                        </span>
                      </div>
                      <div class="radio-label">
                        <input type="radio" v-model="comparisonMetric" value="hours" />
                        <span style="font-size: 12px">Previous hour (Same day)</span>
                      </div>
                      <div class="radio-label" style="text-align: center; align-items: center; justify-content: center">
                        <input type="radio" v-model="comparisonMetric" value="budget" />
                        <span v-if="metric == 'spends'" style="font-size: 12px;  text-transform: capitalize;">Budget
                        </span>
                        <span v-else style="font-size: 12px;  text-transform: capitalize;">{{ metric ? metric : "Budget"
                        }}
                        </span>
                      </div>
                    </div>
                  </b-col>
                </b-row>
                <b-row class="mt-1" :style="triggerType == 'Summary' ? 'display: none' : ''" v-if="!isInscrease ||
                  !metric ||
                  this.comparisonMetric == 'budget' ||
                  isComparisonMetric == false
                  ">
                  <b-col cols="12" :class="!metric ? 'mt-1' : ''">
                    <label style="text-transform: capitalize;">
                      <span v-if="zeroMetricCheckEnabled == true"
                        style="font-size: 12px;  text-transform: capitalize;">Compare Data per</span>
                      <span v-else-if="metric == 'spends'" style="font-size: 12px;  text-transform: capitalize;">Budget
                        per</span>
                      <span v-else style="font-size: 12px;  text-transform: capitalize;">{{ metric ? metric : "Budget" }}
                        per</span>
                      <b-dropdown size="sm" :text="selectedBudgetItem"
                        style="margin-left: 5px; margin-bottom: 5px; width: 95px;" variant="outline-primary"
                        id="dropdown-dropright" dropright>
                        <b-dropdown-item v-for="(item, index) in dropdownBudgetItems" :key="index"
                          @click="selectBudgetItem(item)" :disabled="isLifeTimeDisabled(item)"
                          style="text-transform: lowercase;">
                          {{ item }}
                        </b-dropdown-item>
                      </b-dropdown>
                    </label>
                    <label v-if="zeroMetricCheckEnabled == true"
                      style="font-size: 10px;  text-transform: capitalize; margin-left: 10px; max-width: 228px; color: #2c8d1b;  line-height: 12px; vertical-align: sub;">
                      <span>
                        <i>*You Will Receive A Notification If There<br />Are No
                          Ad Spends Recorded Per
                          {{ this.selectedBudgetItem }}.</i>
                      </span>
                    </label>
                    <input type="number" :style="zeroMetricCheckEnabled == true ? 'display: none' : ''
                      " :class="metric === 'spends' || !metric
    ? 'budget_input_conditionally'
    : 'budget_input'
    " v-model="daily_budget" />
                  </b-col>
                </b-row>
              </div>
              <!-- Client's Trigger Modal - this part of element will show from triggers page -->
              <div v-else>
                <b-row class="mt-1" :style="selectedClient.isComparisonMetric == false
                  ? ' display: none'
                  : ''
                  " v-if="selectedClient.metric || selectedClient.operator">
                  <label style="margin-left: 13px">Comparison Metric<span class="validation">*</span></label>
                  <b-col cols="12">
                    <div class="info-container">
                      <span v-b-tooltip.hover class="info-label tooltip-trigger"
                        title="Compare with previous day's data at the same hour" style="margin-left: 13px">
                        <b-icon icon="info-circle-fill" />
                      </span>
                      <span v-b-tooltip.hover class="info-label info-label-right tooltip-trigger"
                        title="Compare with same day's previous hour's data">
                        <b-icon icon="info-circle-fill" />
                      </span>
                    </div>
                    <div class="radio-container">
                      <div class="radio-label">
                        <input type="radio" v-model="comparisonMetric" value="day" />
                        <span style="font-size: 12px">Previous day (Same hour)
                        </span>
                      </div>
                      <div class="radio-label">
                        <input type="radio" v-model="comparisonMetric" value="hours" />
                        <span style="font-size: 12px">Previous hour (Same day)</span>
                      </div>
                      <div class="radio-label" style="text-align: center; align-items: center; justify-content: center">
                        <input type="radio" v-model="comparisonMetric" value="budget" />
                        <span v-if="selectedClient.metric == 'spends'"
                          style="font-size: 12px;  text-transform: capitalize;">Budget
                        </span>
                        <span v-else style="font-size: 12px;  text-transform: capitalize;">{{
                          selectedClient.metric
                          ? selectedClient.metric
                          : "Budget"
                        }}
                        </span>
                      </div>
                    </div>
                  </b-col>
                </b-row>
                <b-row :style="selectedClient.triggerType == 'Summary'
                  ? 'display: none'
                  : ''
                  " v-if="!selectedClient.metric ||
      !selectedClient.operator ||
      this.comparisonMetric == 'budget' ||
      selectedClient.isComparisonMetric == false
      ">
                  <b-col cols="12" :class="!this.metric ? 'mt-2' : ''">
                    <label style="text-transform: capitalize;">
                      <span v-if="selectedClient.zeroMetricCheckEnabled == true"
                        style="font-size: 12px;  text-transform: capitalize;">Compare Data per</span>
                      <span v-else-if="selectedClient.metric == 'spends'"
                        style="font-size: 12px;  text-transform: capitalize;">Budget per</span>
                      <span v-else style="font-size: 12px;  text-transform: capitalize;">{{
                        selectedClient.metric
                        ? selectedClient.metric
                        : "Budget"
                      }}
                        Per</span>
                      <b-dropdown size="sm" :text="selectedBudgetItem"
                        style="margin-left: 5px; margin-bottom: 5px; width: 95px;" variant="outline-primary"
                        id="dropdown-dropright" dropright>
                        <b-dropdown-item v-for="(item, index) in dropdownBudgetItems" :key="index"
                          @click="selectBudgetItem(item)" :disabled="isLifeTimeDisabled(item)"
                          style="text-transform: lowercase;">
                          {{ item }}
                        </b-dropdown-item>
                      </b-dropdown></label>
                    <label v-if="selectedClient.zeroMetricCheckEnabled == true"
                      style="font-size: 10px;  text-transform: capitalize; margin-left: 10px; max-width: 228px; color: #2c8d1b;  line-height: 12px; vertical-align: sub;">
                      <span>
                        <i>*You Will Receive A Notification If There <br />Are
                          No Ad Spends Recorded Per
                          {{ this.selectedBudgetItem }}.</i></span>
                    </label>
                    <input :style="selectedClient.zeroMetricCheckEnabled == true
                      ? 'display: none'
                      : ''
                      " type="number" :class="selectedClient.metric === 'spends' ||
    !selectedClient.metric
    ? 'budget_input_conditionally'
    : 'budget_input'
    " v-model="daily_budget" />
                  </b-col>
                </b-row>
              </div>

              <div v-if="this.comparisonMetric == 'hours'">
                <b-row v-if="isInscrease || this.comparisonMetric == 'hours'">
                  <b-col cols="12">
                    <label>Select Hour </label>
                    <div class="input-hour-container">
                      <input type="number" min="1" max="24" class="client" v-model="hourNum" @input="checkHourLimits"
                        maxlength="2" />
                      <div class="buttons-group">
                        <button class="up" @click="incrementHour" :disabled="hourNum >= 24">
                          ▲
                        </button>
                        <button class="down" @click="decrementHour" :disabled="hourNum <= 1">
                          ▼
                        </button>
                      </div>
                    </div>
                  </b-col>
                </b-row>
              </div>
              <b-row class="mt-1">
                <div>
                  <div class="date-container">
                    <b-col cols="3"><label>Start Date<span class="validation">*</span></label>
                      <input v-model="startDate" placeholder="Choose a start-date" type="date" :min="myDate"
                        style="width: 220px" />
                    </b-col>
                    <b-col cols="3">
                      <label>End Date<span class="validation">*</span></label>
                      <input v-model="endDate" placeholder="Choose a start-date" type="date" :min="myDate"
                        style="width: 220px" />
                    </b-col>
                  </div>
                  <p v-if="isInvalid" class="text-danger ml-1 mt-0.5 text-xs">
                    End date must be greater than or equal to start date.
                  </p>
                </div>
              </b-row>
              <div class="button-group mb-2">
                <b-row v-if="triggerType == 'Summary' ||
                  selectedClient.triggerType == 'Summary'
                  ">
                  <b-col cols="6">
                    <b-button class="py-1 px-10 mt-2 btn-size" block variant="primary" id="show-btn"
                      @click="handleWarning()" :disabled="fetchByClient === true
                        ? isBtnDisabled
                        : isBtnConditionalDisabled
                        ">
                      Schedule Trigger</b-button>
                  </b-col>
                  <b-col cols="6">
                    <b-button class="py-1 px-10 mt-2 btn-size" variant="primary" id="show-btn"
                      @click="$bvModal.show('showScheduleNow')" :disabled="fetchByClient === true
                        ? isBtnDisabledWithoutDates
                        : isBtnDisabledConditionalWithoutDates
                        ">
                      Send Now</b-button>
                  </b-col>
                </b-row>
                <b-row v-else>
                  <b-col cols="6">
                    <b-button class="py-1 px-10 mt-2 btn-size" block variant="primary" id="show-btn"
                      @click="handleWarning()" :disabled="isBtnDisabledInNonSummary">
                      Schedule Trigger
                    </b-button>
                  </b-col>
                  <b-col cols="6">
                    <b-button class="py-1 px-10 mt-2 btn-size" variant="primary" id="show-btn"
                      @click="$bvModal.show('showScheduleNow')" :disabled="isBtnDisabledWithoutDatesWithNonSummary">
                      Send Now</b-button>
                  </b-col>
                </b-row>
              </div>
            </div>
          </b-card-text>
        </b-modal>
        <div>
          <b-modal id="bv-modal-example" hide-footer v-model="showLastModal">
            <div v-if="warningMessage" class="d-block text-center text-danger">
              <p>{{ this.errorText }}</p>
            </div>
            <div v-if="popupMessage">
              <div class="d-block text-center">
                <h4>
                  Are you sure you want to proceed with scheduling this trigger?
                </h4>
              </div>
              <div class="popup-modal">
                <b-button style="min-width: 150px" class="mt-2"
                  @click="$bvModal.hide('bv-modal-example')">Cancel</b-button>
                <b-button class="ml-1 mt-2" style="min-width: 150px" variant="primary" @click="createTrigger()"
                  :disabled="disabledButton">
                  Yes
                  <span v-if="this.isLoading">
                    <b-spinner small></b-spinner>
                  </span>
                </b-button>
              </div>
              <!-- <div class="d-block text-center">
                <h4>Are you sure about scheduling this Trigger?</h4>
              </div>
              <div class="popup-modal">
                <b-button class=" mt-2" @click="$bvModal.hide('bv-modal-example')"
                  >Cancel</b-button
                >
                <b-button
                  class="ml-1 mt-2"
                  variant="primary"
                  @click="createTrigger()"
                  :disabled="disabledButton"
                  >Yes <span v-if="this.isLoading">
                    <b-spinner small></b-spinner>
                  </span></b-button
                > -->
            </div>
          </b-modal>
          <b-modal id="showScheduleNow" hide-footer hide-header v-model="showSendNowModal">
            <div class="d-block text-center">
              <h4>Please confirm that you want to proceed with the trigger?</h4>
            </div>
            <div class="popup-modal">
              <b-button class="mt-2" @click="$bvModal.hide('showScheduleNow')" style="min-width: 150px">Cancel</b-button>
              <b-button style="min-width: 150px" class="ml-1 mt-2" variant="primary" @click="sendNowTrigger()"
                :disabled="disabledButton">
                Yes
                <span v-if="this.isLoading">
                  <b-spinner small></b-spinner>
                </span>
              </b-button>
            </div>
          </b-modal>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BTable,
  BCard,
  BCardBody,
  BButton,
  BModal,
  BCardText,
  BRow,
  BCol,
  BFormTimepicker,
  BFormTags,
  BFormCheckboxGroup,
  BFormCheckbox,
  BFormDatepicker,
  BFormGroup,
  BInputGroup,
  BInputGroupPrepend,
  BFormInput,
  BFormSelect,
  BSpinner,
  BFormRadio,
  BIcon,
  BDropdown,
  BDropdownItem,
} from "bootstrap-vue";
import vSelect from "vue-select";
import VuePhoneNumberInput from "vue-phone-number-input";
import axios from "axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { getUserToken } from "@/auth/utils";
import validator from "validator";

export default {
  name: "Modal",
  data() {
    return {
      fetchByClient: true,
      allChannels: ["google-ads", "amazon", "meta"],
      campaignType: [
        "ecommerce",
        "leadgen",
        "branding",
        // , "apps"
      ],
      percentage: 0,
      isValidPhoneNumber: true,
      isValidPhoneEmail: true,
      myDate: new Date().toISOString().slice(0, 10),
      minDate: "",
      contact: "",
      isValidationAllowed: false,
      triggerData: null,
      filteredList: [],
      fetchClientsList: [],
      startDate: "",
      endDate: "",
      show: false,
      showLastModal: false,
      showSendNowModal: false,
      selectedClient: "",
      emailAddresses: [],
      sms: "",
      daily_budget: 0,
      selectedId: "",
      dirty: false,
      hideinput: false,
      isLoading: false,
      disabledButton: false,
      errorText: "",
      warningMessage: false,
      popupMessage: true,
      comparisonMetric: "budget",
      hourNum: 1,
      showHourWarning: false,
      selectedCampaignType: "",
      selectedCampaigns: [],
      selectedChannels: [],
      selectedChannelsName: [],
      selectedCampaignsName: [],
      campaigns: [],
      isCampaignsLoading: false,
      getCummulativeData: false,
      dropdownBudgetItems: ["hour", "day", "month", "lifetime"],
      selectedBudgetItem: "hour",
      selectedChannel: "google-ads",
      selectedAccount: "Social Beat Digital Marketing",
    };
  },
  props: {
    buttonSize: {
      type: String,
    },
    iconSize: {
      type: String,
    },
    triggerId: {
      type: String,
    },
    isPercentage: {
      type: Boolean,
    },
    isInscrease: {
      type: String,
    },
    scheduleModal: {
      type: Boolean,
    },
    metric: {
      type: String,
    },
    isTriggerModal: {
      type: Boolean,
    },
    clientID: {
      type: String,
    },
    isComparisonMetric: {
      type: Boolean,
    },
    zeroMetricCheckEnabled: {
      type: Boolean,
    },
    triggerType: {
      type: String,
    },
  },
  components: {
    BSpinner,
    BFormCheckboxGroup,
    BCard,
    BTable,
    BFormCheckbox,
    BCardBody,
    BButton,
    BModal,
    BCardText,
    BFormSelect,
    BRow,
    BCol,
    BFormTimepicker,
    BFormTags,
    ToastificationContent,
    BFormDatepicker,
    BFormGroup,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    VuePhoneNumberInput,
    BSpinner,
    BFormRadio,
    vSelect,
    BIcon,
    BDropdown,
    BDropdownItem,
  },
  computed: {
    dynamicCols() {
      return this.isIncrease ? 12 : 6; // Set the desired col value based on the condition
    },
    // isCampaignsLoadingValue(){
    // if(this.selectedClient.length > 0){
    //   this.isCampaignsLoading = true;
    // }
    // },
    isInvalid() {
      if (this.startDate && this.endDate) {
        return this.startDate > this.endDate;
      }
      return false;
    },
    isBtnConditionalDisabled() {
      // if( fetchByClient === true){
      return (
        this.startDate === "" ||
        this.endDate === "" ||
        this.emailAddresses.length === 0 ||
        (this.selectedChannels.length === 0 && this.fetchByClient === true) ||
        this.contact.length === 0 ||
        this.daily_budget.length === 0 ||
        this.startDate > this.endDate
      );
    },
    isBtnDisabledInNonSummary() {
      return (
        this.selectedClient.length === 0 ||
        this.selectedChannels.length === 0 ||
        this.startDate === "" ||
        this.endDate === "" ||
        this.emailAddresses.length === 0 ||
        this.contact.length === 0 ||
        this.daily_budget.length === 0 ||
        this.startDate > this.endDate
      );
    },
    isBtnDisabled() {
      // if( fetchByClient === true){
      return (
        this.selectedClient.length === 0 ||
        this.selectedChannels.length === 0 ||
        this.selectedCampaignType.length === 0 ||
        this.startDate === "" ||
        this.endDate === "" ||
        this.emailAddresses.length === 0 ||
        this.contact.length === 0 ||
        // this.daily_budget.length === 0 ||
        this.startDate > this.endDate
      );
      // }else if( fetchByClient === false){
      //   return (
      //   this.startDate === "" ||
      //   this.endDate === "" ||
      //   this.emailAddresses.length === 0 ||
      //   this.contact.length === 0 ||
      //   this.daily_budget.length === 0 ||
      //   this.startDate > this.endDate
      // );
      // }else{
      //   this.selectedClient.length === 0 ||
      //   this.startDate === "" ||
      //   this.endDate === "" ||
      //   this.emailAddresses.length === 0 ||
      //   this.contact.length === 0 ||
      //   this.daily_budget.length === 0 ||
      //   this.startDate > this.endDate
      // }
    },
    isBtnDisabledWithoutDates() {
      return (
        this.selectedChannels.length === 0 ||
        this.selectedClient.length === 0 ||
        this.selectedCampaignType.length === 0 ||
        this.contact.length === 0 ||
        this.emailAddresses.length === 0
      );
    },
    isBtnDisabledWithoutDatesWithNonSummary() {
      return (
        this.selectedChannels.length === 0 ||
        this.selectedClient.length === 0 ||
        this.contact.length === 0 ||
        this.emailAddresses.length === 0
      );
    },
    isBtnDisabledConditionalWithoutDates() {
      return this.contact.length === 0 || this.emailAddresses.length === 0;
    },
    state() {
      return this.dirty
        ? this.emailAddresses.length > 2 && this.emailAddresses.length < 9
        : null;
    },
  },
  watch: {
    fetchByClient(newValue, oldValue) {
      this.doSomethingOnFetchByClientChange(newValue);
    },
    selectedCampaigns(newValue, oldValue) {
      if (
        this.selectedCampaigns.length > 1 &&
        ["lifetime", "month"].includes(this.selectedBudgetItem)
      ) {
        this.selectedBudgetItem = "hour";
      }
    },
    selectedClient(newValue, oldValue) {
      this.getCampaigns();
    },
    tags(newValue, oldValue) {
      this.dirty = true;
    },
  },
  mounted() {
    // this.getCampaigns();
    if (this.isTriggerModal == false) {
      // this.getCampaigns();
      axios
        .get(`${process.env.VUE_APP_SERVER_URL}/api/triggers`, {
          headers: {
            Authorization: `Bearer ${getUserToken()}`,
            "Content-type": "application/json",
          },
        })
        .then((response) => {
          this.triggerData = response.data.triggers;
        })
        .catch((error) => {
          console.log(error);
          this.errored = true;
        });
    }
    this.minDate = new Date().toISOString().split("T")[0];
    this.fetchClients();
  },
  methods: {
    doSomethingOnFetchByClientChange(newValue) {
      if (newValue === false) {
        this.selectedChannel = "google-ads";
      }
    },
    isLifeTimeDisabled(item) {
      return (
        (item === "lifetime" && !this.meetsConditionForLifetime()) ||
        (item === "month" && !this.meetsConditionForLifetime())
      );
    },
    meetsConditionForLifetime() {
      let isMatchCondition = this.selectedCampaigns.length === 1;
      return isMatchCondition;
    },
    selectBudgetItem(item) {
      this.selectedBudgetItem = item;
    },
    getCampaigns() {
      this.campaigns = [];
      this.selectedCampaigns = [];
      this.isCampaignsLoading = true;
      let clientID = this.selectedClient;
      if (this.isTriggerModal == false) {
        clientID = this.clientID;
      }
      axios
        .post(
          `${process.env.VUE_APP_SERVER_URL}/api/google-ads/get-campaigns`,
          {
            clientID,
          },
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {
          this.isCampaignsLoading = false;
          this.campaigns = response.data;
        })
        .catch((error) => {
          console.log(error.response.data);
          this.isCampaignsLoading = false;
        });
    },
    closeScheduleClientPopUp() {
      this.$emit("closeScheduleClientPopUp", true);
    },
    handleWarning() {
      this.showLastModal = true;
      this.warningMessage = false;
      this.popupMessage = true;
    },
    tagValidator(tag) {
      // Individual tag validator function
      return tag === tag.toLowerCase() && tag.length > 2 && tag.length < 6;
    },
    validatePhoneNumber() {
      const validationRegex = /^\d{10}$/;
      if (this.contact.match(validationRegex)) {
        this.isValidPhoneNumber = true;
      } else if (this.contact == "") {
        this.isValidPhoneNumber = true;
      } else {
        this.isValidPhoneNumber = false;
      }
    },
    validateEmail() {
      const validateEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/;
      4;
      if (this.isTriggerModal == true) {
        if (this.emailAddresses.match(validateEmail)) {
          this.isValidPhoneEmail = true;
        } else if (this.emailAddresses == "") {
          this.isValidPhoneEmail = true;
        } else {
          this.isValidPhoneEmail = false;
        }
      } else {
        if (this.emailAddress.match(validateEmail)) {
          this.isValidPhoneEmail = true;
        } else if (this.emailAddress == "") {
          this.isValidPhoneEmail = true;
        } else {
          this.isValidPhoneEmail = false;
        }
      }
    },
    incrementHour() {
      this.hourNum++;
    },
    decrementHour() {
      if (this.hourNum > 1) {
        this.hourNum--;
      }
    },
    checkHourLimits() {
      if (this.hourNum > 24) {
        this.hourNum = 24;
      } else if (this.hourNum < 1) {
        this.hourNum = 1;
      }
    },
    fetchClients() {
      this.isBusy = true;
      axios
        .post(
          `${process.env.VUE_APP_SERVER_URL}/api/client/get`,
          {
            limit: 50,
          },
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
              "Content-type": "application/json",
            },
          }
        )
        .then((response) => {
          this.fetchClientsList = response.data.clients;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    handleClose() {
      this.$emit("close");
    },
    closeTriggerPopUp() {
      this.show = false;
    },
    showToast(title, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon: variant == "success" ? "CheckCircleIcon" : "AlertCircleIcon",
          text,
          variant,
        },
      });
    },
    showModal() {
      this.show = true;
    },
    createTrigger() {
      const startDate = this.startDate;
      const endDate = this.endDate;
      let clientID = this.selectedClient;
      let emailAddresses = this.emailAddresses.join(",");
      const contact = "+91" + this.contact;
      let daily_budget = this.daily_budget;
      let triggerID = this.triggerId;
      this.isLoading = true;
      this.disabledButton = true;
      const percentage = this.percentage;
      const campaigns = this.selectedCampaigns;
      let comparisonMetric = this.comparisonMetric;
      const budgetMetric = this.selectedBudgetItem;
      let avg_last_hours = this.hourNum;
      const getCummulativeData = this.getCummulativeData;
      const fetchByClient = this.fetchByClient;
      const campaignType = this.selectedCampaignType;
      if (fetchByClient === false) {
        this.selectedChannel = "";
      }
      const channel = {};
      if (typeof this.selectedChannels === "string") {
        const channelArray = this.selectedChannels.split(",");

        channelArray.forEach((item) => {
          channel[item] = true;
        });
      } else {
        this.selectedChannels.forEach((item) => {
          channel[item] = true;
        });
      }
      if (this.isTriggerModal == false) {
        clientID = this.clientID;
        triggerID = this.selectedClient._id;
        if (!this.selectedClient.metric || !this.selectedClient.operator) {
          avg_last_hours = undefined;
        } else {
          if (comparisonMetric != "budget") {
            daily_budget = undefined;
          }
        }
      }
      if (!this.metric || !this.isInscrease) {
        avg_last_hours = undefined;
      } else {
        if (comparisonMetric != "budget") {
          daily_budget = undefined;
        }
      }
      axios
        .post(
          `${process.env.VUE_APP_SERVER_URL}/api/triggers/schedule`,
          {
            startDate,
            endDate,
            clientID,
            emailAddresses,
            daily_budget,
            contact,
            triggerID,
            percentage,
            comparisonMetric,
            avg_last_hours,
            campaigns,
            getCummulativeData,
            budgetMetric,
            channel,
            fetchByClient,
            campaignType,
          },
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
              "Content-type": "application/json",
            },
          }
        )
        .then((response) => {
          this.showToast(
            "Trigger Scheduled",
            "Trigger has been Scheduled succesfully.",
            "success"
          );
          this.show = false;
          this.isLoading = false;
          this.disabledButton = false;
          this.showLastModal = false;
          this.$emit("closeScheduleClientPopUp", true);
        })
        .catch((error) => {
          console.log(error.response.data);
          // this.showToast("Error", "Unknown error occured.", "danger");
          this.warningMessage = true;
          this.popupMessage = false;
          this.isLoading = false;
          this.disabledButton = false;
          this.errorText = error.response.data.error;
        });
    },
    sendNowTrigger() {
      let clientID = this.selectedClient;
      let emailAddresses = this.emailAddresses;
      let daily_budget = this.daily_budget;
      const contact = "+91" + this.contact;
      let triggerID = this.triggerId;
      this.isLoading = true;
      this.disabledButton = true;
      const percentage = this.percentage;
      let comparisonMetric = this.comparisonMetric;
      let avg_last_hours = this.hourNum;
      const budgetMetric = this.selectedBudgetItem;
      const getCummulativeData = this.getCummulativeData;
      const fetchByClient = this.fetchByClient;
      const campaignType = this.selectedCampaignType;
      if (this.fetchByClient === false) {
        this.selectedChannel = "";
      }
      const channel = {};
      if (typeof this.selectedChannels === "string") {
        const channelArray = this.selectedChannels.split(",");
        channelArray.forEach((item) => {
          channel[item] = true;
        });
      } else {
        this.selectedChannels.forEach((item) => {
          channel[item] = true;
        });
      }
      if (!this.metric || !this.isInscrease) {
        avg_last_hours = undefined;
      } else {
        if (comparisonMetric != "budget") {
          daily_budget = undefined;
        }
      }
      const campaigns = (this.selectedCampaignsName = this.selectedCampaigns.map(
        (item) => {
          return item.name;
        }
      ));
      if (this.isTriggerModal == false) {
        clientID = this.clientID;
        triggerID = this.selectedClient._id;
        if (!this.selectedClient.metric || !this.selectedClient.operator) {
          avg_last_hours = undefined;
        } else {
          if (comparisonMetric != "budget") {
            daily_budget = undefined;
          }
        }
      }
      axios
        .post(
          `${process.env.VUE_APP_SERVER_URL}/api/google-ads/trigger/now`,
          {
            clientID,
            emailAddresses,
            daily_budget,
            contact,
            triggerID,
            percentage,
            comparisonMetric,
            avg_last_hours,
            campaigns,
            budgetMetric,
            getCummulativeData,
            channel,
            fetchByClient,
            campaignType,
          },
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
              "Content-type": "application/json",
            },
          }
        )
        .then((response) => {
          this.showToast("Success", "Notification sent!", "success");
          this.show = false;
          this.isLoading = false;
          this.disabledButton = false;
          this.showLastModal = false;
          this.showSendNowModal = false;
        })
        .catch((error) => {
          console.log(error.response.data);
          this.showToast("Error", "Unknown error occured.", "danger");
          this.isLoading = false;
          this.disabledButton = false;
        });
    },
    tagValidator(tag) {
      return validator.isEmail(tag);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-select.scss";

[dir] .modal-item {
  padding: 0px 4px !important;
}

.metric-container {
  display: flex;
}

.modal_trigger {
  position: fixed;
  z-index: 999;
  padding-top: 5px;
  left: 0;
  top: 20px;
  width: 100%;
  height: 100%;
  overflow: auto;
  -webkit-animation-name: animatetop;
  -webkit-animation-duration: 0.4s;
  animation-name: animatetop;
  animation-duration: 0.4s;
}

.modal {
  position: relative;
  background-color: #fefefe;
  margin: auto;
  padding: 0;
  border: none;
  width: 100%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

button {
  font-size: 16px;
  font-weight: 500;
  color: #200e6b;
}

.popup-modal {
  justify-content: center;
  display: flex;
}

.modal-item {
  justify-content: center;
  align-items: center;
  padding: 2px 30px;
}

.client {
  width: 452px !important;
  height: 38px;
  border: 0px;
  background-color: white;
  border: 1px solid rgb(218, 211, 211);
  border-radius: 5px;
  padding-left: 8px;
  color: rgb(163, 153, 153);
}

.budget_input {
  width: 300px !important;
  height: 30px;
  border: 0px;
  margin-left: 8px;
  background-color: white;
  border: 1px solid rgb(218, 211, 211);
  border-radius: 5px;
  padding-left: 8px;
  color: rgb(163, 153, 153);
}

.budget_input_conditionally {
  width: 285px !important;
  height: 30px;
  border: 0px;
  margin-left: 10px;
  background-color: white;
  border: 1px solid rgb(218, 211, 211);
  border-radius: 5px;
  padding-left: 8px;
  color: rgb(163, 153, 153);
}

.client-select {
  border-right: 10px solid transparent;
  outline: 1px solid #d8d6de;
  width: 225px !important;
  height: 38px;
  border: 0px;
  padding-left: 10px;
  background-color: white;
  border-radius: 5px;
  color: rgb(163, 153, 153);
}

.client-select-conditionally {
  border-right: 10px solid transparent;
  outline: 1px solid #d8d6de;
  width: 464px !important;
  height: 38px;
  background-color: white;
  border: 0px;
  border-radius: 5px;
  padding-left: 8px;
  color: rgb(163, 153, 153);
}

// .client-channel-select-conditionally{
//   border-right: 10px solid transparent;
//   outline: 1px solid #d8d6de;
//   width: 452px !important;
//   height: 50px;
//   background-color: white;
//   border: 0px;
//   border-radius: 5px;
//   padding-left: 8px;
//   color: rgb(163, 153, 153);
// }

input {
  border-radius: 5px;
  padding-left: 8px;
  padding-right: 8px;
  width: 205px;
  height: 38px;
  background-color: white;
  border: 1px solid rgb(218, 211, 211);
  color: rgb(163, 153, 153);
}

.validation {
  color: red;
  margin-left: 2px;
}

.validation {
  color: red;
  margin-left: 2px;
  border-radius: 5px;
}

.invalid {
  border: 1px solid red;
  border-radius: 5px;
}

#client-name:focus {
  outline: none !important;
}

.invalid-warning {
  margin: 2px auto;
  color: red;
  font-size: 12px;
  text-align: left;
}

.button-group {
  display: flex;
  justify-content: space-between;
}

.btn-size {
  width: 225px;
}

.date-container {
  display: flex;
  gap: 7.2rem;
}

.emailTag {
  padding: 0px 10px;
}

.checkbox-container {
  display: flex;
  gap: 5px;
}

.checkbox-container>label {
  margin-top: 8px;
}

.input-hour-container {
  position: relative;
  display: inline-block;
}

.buttons-group {
  position: absolute;
  top: 25%;
  right: 15px;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 20px;
}

.buttons-group>button {
  width: 10px;
  height: 15px;
  background: none;
  border: none;
  color: #432f94;
}

.buttons-group>button:hover {
  color: #818089;
}

.radio-label input[type="radio"] {
  transform: scale(0.6);
  margin: 0;
  padding: 0;
  width: 30px;
}

.radio-label {
  display: flex;
  align-items: center;
}

.radio-container {
  display: flex;
}

.info-container {
  display: flex;
  margin-left: 12rem;
  margin-bottom: -14px;
}

.info-label-right {
  margin-left: 12rem;
  margin-bottom: -14px;
}

// .info-label {
//   display: flex;
//   align-items: center;
//   float: right;
// }

.campaigns {
  border: 1px solid rgb(218, 211, 211);
  border-radius: 5px;
}

.tooltip-trigger {
  position: relative;
  cursor: pointer;
}

// .tooltip-trigger:hover::after {
//   visibility: visible;
//   opacity: 1;
// }

.info-icon {
  stroke-width: 2;
  color: grey;
}

label {
  font-size: 12px;
  margin-bottom: 0px !important;
}

.Xicon:hover {
  color: #200e6b;
  transform: scale(1.2);
}

.dropright .dropdown-menu {
  min-width: 5rem !important;
}

.custom-select option:disabled {
  background-color: #efefef;
  color: #b8c2cc;
}
</style>
