<template>
  <div>
    <b-card class="screenAlignmentDataTable">
      <div>
        <div class="schedule-hearder">
          <div>
            <b-card-title>
              <h3 class="app-title mdsm mt-1">{{ title }}</h3>
            </b-card-title>
          </div>
          <div style="display: flex; justify-content: space-between;gap: 10px;">
            <div
              style="display: flex; justify-content: space-between;gap: 10px;"
            >
              <div style="width: 150px;">
                <b-form-group>
                  <label for="client-name">Sub Category Type:</label>
                  <b-form-select
                    @input="getSubCatergoryChange"
                    class="uppercase-options"
                    v-model="selectedSubCategoriesType"
                    :options="this.subCampaign && this.subCampaign.sub_category"
                  />
                </b-form-group>
              </div>
              <div style="width: 170px;">
                <b-form-group>
                  <label for="client-name">Sub Sub Category Type:</label>
                  <b-form-select
                    @input="getSubSubCatergoryChange"
                    class="uppercase-options"
                    v-model="selectedSubSubCategoriesType"
                    :options="
                      this.subCampaign && this.subCampaign.sub_sub_category
                    "
                  />
                </b-form-group>
              </div>
              <div style="width: 130px;">
                <b-form-group>
                  <label for="client-name">BCG:</label>
                  <b-form-select
                    @input="getBCGCatergoryChange"
                    class="uppercase-options"
                    v-model="selectedBcg"
                    :options="this.subCampaign && this.subCampaign.bcg"
                  />
                </b-form-group>
              </div>
              <div style="width: 130px;">
                <b-form-group>
                  <label for="client-name">Ad Type:</label>
                  <b-form-select
                    @input="getAdTypeCatergoryChange"
                    class="uppercase-options"
                    v-model="selectedAdType"
                    :options="this.subCampaign && this.subCampaign.ad_type"
                  />
                </b-form-group>
              </div>
            </div>
            <!-- <input
              type="text"
              style="width: 300px; margin-top:23px"
              v-model="searchTerm"
              placeholder="Campaign name.."
            /> -->
            <div></div>
            <!-- <b-button  @click="exportData" class="exportButton"  size="sm" variant="primary" style=" margin-top:23px"
              ><span style="font-weight: bold;">
                Export Data
                <feather-icon
                  icon="DownloadIcon"
                  size="15"
                  style="margin-left: 5px;"/></span
            ></b-button> -->
          </div>
        </div>
      </div>
      <div>
        <b-col cols="12">
          <b-tabs fill>
            <b-tab title="Sub Category" @click="changeSearchViewTab('subCategory')"> </b-tab>
            <b-tab title="Ad Type" @click="changeSearchViewTab('adType')"> </b-tab>
            <b-tab title="BCG" @click="changeSearchViewTab('BCG')"> </b-tab>
          </b-tabs>
        </b-col>
        <div v-if="this.isDataLoadingForSub || this.isDataAdTypeLoadingForSub || this.isDataBCGLoadingForSub" class="spinnerCentered">
          <multiChannelSpinner />
        </div>
        <div v-else>
          <template v-if="this.searchViewTab === 'subCategory'">
            <div class="subCategoryClass">
              <table style="border:2px solid black">
                <tr>
                  <th>Sub Category</th>
                  <th>Sub Sub Category</th>
                  <th>Imp</th>
                  <th>Clicks</th>
                  <th>Spend</th>
                  <th>Order</th>
                  <th>Sales</th>
                  <th>CTR</th>
                  <th>CPC</th>
                  <th>CVR</th>
                  <th>AOV</th>
                  <th>ROAS</th>
                  <th>ACOS</th>
                </tr>
                <template v-for="(categoryData, category) in chartSubCategoryData.result">
                  <div style="padding: 2px;"></div>
                  <template
                    v-for="(row, rowIndex) in Object.values(categoryData)"
                  >
                    <tr>
                      <th v-if="rowIndex === 0" :rowspan="totalNestedKeys">{{ category }}</th>
                      <td style="font-weight:bold">
                        {{ Object.keys(categoryData)[rowIndex] }}
                      </td>
                      <td>{{ row.impressions }}</td>
                      <td>{{ row.clicks }}</td>
                      <td>{{ row.spends }}</td>
                      <td>{{ row.orders }}</td>
                      <td>{{ row.sales }}</td>
                      <td>{{ row.ctr }}</td>
                      <td>{{ row.costPerClick }}</td>
                      <td>{{ row.conversionRate }}</td>
                      <td>{{ row.aov }}</td>
                      <td>{{ row.roas }}</td>
                      <td>{{ row.acos }}</td>
                    </tr>
                  </template>
                </template>
                <template>
                  <tr>
                    <th colspan="2" style="font-weight:bold"> Total</th>
                    <td>{{ this.chartSubCategoryData && this.chartSubCategoryData.total.subTotal.impressions }}</td>
                    <td>{{ this.chartSubCategoryData &&  this.chartSubCategoryData.total.subTotal.clicks }}</td>
                    <td>{{ this.chartSubCategoryData &&  this.chartSubCategoryData.total.subTotal.spends }}</td>
                    <td>{{ this.chartSubCategoryData &&  this.chartSubCategoryData.total.subTotal.orders }}</td>
                    <td>{{ this.chartSubCategoryData &&  this.chartSubCategoryData.total.subTotal.sales }}</td>
                    <td>{{   this.chartSubCategoryData && this.chartSubCategoryData.total.subTotal.ctr }}</td>
                    <td>{{  this.chartSubCategoryData &&  this.chartSubCategoryData.total.subTotal.costPerClick }}</td>
                    <td>{{  this.chartSubCategoryData &&  this.chartSubCategoryData.total.subTotal.conversionRate }}</td>
                    <td>{{  this.chartSubCategoryData &&  this.chartSubCategoryData.total.subTotal.aov }}</td>
                    <td>{{  this.chartSubCategoryData &&  this.chartSubCategoryData.total.subTotal.roas }}</td>
                    <td>{{   this.chartSubCategoryData && this.chartSubCategoryData.total.subTotal.acos }}</td>
                  </tr>
                </template>
                
              </table>
            </div>
          </template>
          <template v-if="this.searchViewTab === 'adType'">
              <div class="subCategoryClass">
                  <table style="border:2px solid black; width: 100%; border-collapse: collapse;">
                    <thead>
                      <tr>
                        <th>Sub Category</th>
                        <th>Imp</th>
                        <th>Clicks</th>
                        <th>Spend</th>
                        <th>Order</th>
                        <th>Sales</th>
                        <th>CTR</th>
                        <th>CPC</th>
                        <th>CVR</th>
                        <th>AOV</th>
                        <th>ROAS</th>
                        <th>ACOS</th>
                      </tr>
                    </thead>
                    <tbody>
                      <template v-for="(categoryData, category) in chartAdTypeData">
                        <!-- Create a new row for each data item -->
                        <tr :key="category">
                          <th rowspan="1">{{ category }}</th>
                          <td>{{ categoryData.impressions }}</td>
                          <td>{{ categoryData.clicks }}</td>
                          <td>{{ categoryData.spends }}</td>
                          <td>{{ categoryData.orders }}</td>
                          <td>{{ categoryData.sales }}</td>
                          <td>{{ categoryData.ctr }}</td>
                          <td>{{ categoryData.costPerClick }}</td>
                          <td>{{ categoryData.conversionRate }}</td>
                          <td>{{ categoryData.aov }}</td>
                          <td>{{ categoryData.roas }}</td>
                          <td>{{ categoryData.acos }}</td>
                        </tr>
                      </template>
                    </tbody>
                  </table>
                </div>
           </template>
          <template v-if="this.searchViewTab === 'BCG'">
              <div class="subCategoryClass">
                  <table style="border:2px solid black; width: 100%; border-collapse: collapse;">
                    <thead>
                      <tr>
                        <th>Sub Category</th>
                        <th>Imp</th>
                        <th>Clicks</th>
                        <th>Spend</th>
                        <th>Order</th>
                        <th>Sales</th>
                        <th>CTR</th>
                        <th>CPC</th>
                        <th>CVR</th>
                        <th>AOV</th>
                        <th>ROAS</th>
                        <th>ACOS</th>
                      </tr>
                    </thead>
                    <tbody>
                      <template v-for="(categoryData, category) in chartBCGData">
                        <!-- Create a new row for each data item -->
                        <tr :key="category">
                          <th rowspan="1">{{ category }}</th>
                          <td>{{ categoryData.impressions }}</td>
                          <td>{{ categoryData.clicks }}</td>
                          <td>{{ categoryData.spends }}</td>
                          <td>{{ categoryData.orders }}</td>
                          <td>{{ categoryData.sales }}</td>
                          <td>{{ categoryData.ctr }}</td>
                          <td>{{ categoryData.costPerClick }}</td>
                          <td>{{ categoryData.conversionRate }}</td>
                          <td>{{ categoryData.aov }}</td>
                          <td>{{ categoryData.roas }}</td>
                          <td>{{ categoryData.acos }}</td>
                        </tr>
                      </template>
                    </tbody>
                  </table>
                </div>
           </template>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BCard,
  BCardTitle,
  BButton,
  BTab,
  BTabs,
  BCol,
  BRow,
} from "bootstrap-vue";
import multiChannelSpinner from "@/components/client/multiChannelSpinner.vue";
import { VueGoodTable } from "vue-good-table";
import commaNumber from "comma-number";
import millify from "millify";
import {
  sortFuntionStringintoNumericVal,
  sortFuntionNumericVal,
} from "@/components/client/SortingFunction.js";

export default {
  components: {
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BCard,
    BCardTitle,
    BButton,
    multiChannelSpinner,
    BTab,
    BTabs,
    BRow,
    BCol,
  },
  props: {
    chartData: {
      type: Array,
      default: () => {
        return [];
      },
    },
    chartSubCategoryData: {
      type: Object,
      default: () => {
        return {};
      },
    },
    chartAdTypeData: {
      type: Object,
      default: () => {
        return {};
      },
    },
    chartBCGData: {
      type: Object,
      default: () => {
        return {};
      },
    },
    businessType: {
      type: String,
    },
    type: {
      type: String,
      default: "date",
    },
    isDataAdTypeLoadingForSub: {
      type: Boolean,
    },
    isDataLoadingForSub: {
      type: Boolean,
    },
    isDataBCGLoadingForSub: {
      type: Boolean,
    },
    title: {
      type: String,
    },
    exportsType: {
      type: String,
    },
    subCampaign: {
      type: Object,
    },
  },
  data() {
    return {
      searchViewTab:"",
      selectedSubCategoriesType: "all",
      selectedSubSubCategoriesType: "all",
      selectedBcg: "all",
      selectedAdType:"all",
      pageLength: 7,
      searchTerm: "",
      currentPage: 1,
    };
  },
  watch: {
    chartData(newVal, oldVal) {
      // this.chartData = newVal
      this.currentPage = 1;
    },
  },
  computed: {
    totalNestedKeys() {
      let keyCount = 0;
  // Safely access `result` inside `chartSubCategoryData`
  if (this.chartSubCategoryData && this.chartSubCategoryData.result) {
    Object.values(this.chartSubCategoryData.result).forEach(innerObj => {
      if (typeof innerObj === 'object' && innerObj !== null) {
        keyCount =  Object.keys(innerObj).length  // Count keys in each nested object
      }
    });
  }
  return keyCount;
  },
    tableColumns() {
      let columns = [
        {
          label: "Campaign Name",
          field: "campaignName",
          thClass: "campaign-name-th-class",
          tdClass: "text-center break-text",
          thClass: "text-center",
          width: "180px",
        },
        {
          label: "Impressions",
          field: "impressions",
          sortFn: this.sortFn,
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "Clicks",
          field: "clicks",
          sortFn: this.sortFn,
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "Spends",
          field: "spends",
          sortFn: this.sortFn,
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "Sales",
          field: "sales",
          sortFn: this.sortFn,
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "Orders",
          field: "orders",
          sortFn: this.sortFn,
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "CTR",
          field: "ctr",
          sortFn: this.sortFn,
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "CPC",
          field: "costPerClick",
          sortFn: this.sortFn,
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "CVR",
          field: "conversionRate",
          sortFn: this.sortFn,
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "ACOS",
          field: "acos",
          sortFn: this.sortFn,
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "ROAS",
          field: "roas",
          sortFn: this.sortFn,
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "AOV",
          field: "aov",
          sortFn: this.sortFn,
          tdClass: "text-center",
          thClass: "text-center",
        },
      ];
      return columns;
    },
  },
  methods: {
    changeSearchViewTab(name) {
      this.searchViewTab = name;
    },
    getSubCatergoryChange(val) {
      this.$emit("subSearchCatergoryChange", val);
    },
    getSubSubCatergoryChange(val) {
      this.$emit("subSearchSubCatergoryChange", val);
    },
    getBCGCatergoryChange(val) {
      this.$emit("bCGSearchCatergoryChange", val);
    },
    getAdTypeCatergoryChange(val) {
      this.$emit("subSearchAdTypeCatergoryChange", val);
    },
    getFormattedText(text, field) {
      if (field === "clicks") {
        return millify(text);
      } else if (field === "impressions") {
        return millify(text);
      } else if (field === "costMicros") {
        return "₹ " + millify(text);
      } else if (field === "conversions") {
        return millify(text.toFixed(2));
      } else if (field === "ctr") {
        return (text * 100).toFixed(2) + "%";
      } else if (field === "avg cpm" || field === "avg_cpm") {
        return "₹ " + (text * 1000).toFixed(2);
      } else if (field === "avg cpc" || field === "avg_cpc") {
        return "₹ " + text.toFixed(2);
      } else if (field === "cpc") {
        return text.toFixed(2);
      } else {
        return text;
      }
    },
    //      includeDateIntoData(array) {
    //       return array.map(item => {
    //         const { formattedData, ...rest } = item;
    //         return {
    //             date: item.date,
    //             ...formattedData
    //         };
    //     });
    // },
    exportData() {
      // Determine the columns to display based on the channel type
      let displayArr = {};

      if (this.exportsType === "amazonCampaign") {
        displayArr = displayArr = {
          campaignName: "Campaign Name",
          impressions: "Impressions",
          clicks: "Clicks",
          spends: "Spends",
          sales: "Sales",
          orders: "Orders",
          ctr: "CTR",
          costPerClick: "CPC",
          conversionRate: "CVR",
          acos: "ACOS",
          roas: "ROAS",
          aov: "AOV",
        };
      }
      // Helper function to escape and quote each value for CSV
      const escapeCSV = (str) => {
        if (str === null || str === undefined) {
          return '""';
        }
        return `"${String(str).replace(/"/g, '""')}"`;
      };

      // Generate CSV header based on the displayArr
      const header = Object.keys(displayArr)
        .map((field) => escapeCSV(displayArr[field]))
        .join(",");

      // Generate CSV rows based on the displayArr
      const rows = this.chartData.map((item) =>
        Object.keys(displayArr)
          .map((key) => escapeCSV(item[key]))
          .join(",")
      );

      // Combine header and rows, separated by newline
      const csv = [header].concat(rows).join("\n");

      // Create a Blob for the CSV
      const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });

      // Create and trigger a temporary download link
      const link = document.createElement("a");
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", `${this.type}.csv`);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    },

    sortFn(a, b) {
      if (typeof a === "string" && typeof b === "string") {
        return sortFuntionStringintoNumericVal(a, b);
      } else {
        return sortFuntionNumericVal(a, b);
      }
    },
  },
  mounted() {
    this.searchViewTab = "subCategory"
    this.currentPage = 1;
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";

.subCategoryClass {
    overflow-x: auto; 
    -webkit-overflow-scrolling: touch; 
    padding: 5px;
  table {
    width: 100%;
    border-collapse: collapse;
    font-family: Arial, sans-serif;
    margin: 5px 0;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    overflow: hidden;
  }

  th {
    background-color: #7c93c3;
    color: white;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    padding: 12px;
    font-size: 14px;
  }

  td {
    padding: 12px;
    font-size: 14px;
    color: #333;
    background-color: #f9f9f9;
  }

  th,
  td {
    border: 1px solid #ddd;
    text-align: center;
  }

  tr:nth-child(even) td {
    background-color: #f2f2f2;
  }

  tr:hover td {
    background-color: #f1f7ff;
    color: #333;
  }
}

.campaign-name-field {
  max-width: 200px;
}

.break-text {
  font-size: 12px;
  max-width: 150px;
  white-space: normal;
  overflow: visible;
}

.mainTable {
  padding: 6px !important;
}
.screenAlignmentDataTable {
  .card-body {
    padding: 0.6rem !important;
  }
}
.schedule-hearder {
  display: flex;
  padding: 0 10px;
  justify-content: space-between;
}
input {
  border-radius: 5px;
  padding-left: 8px;
  padding-right: 8px;
  width: 210px !important;
  height: 38px;
  background-color: white;
  border: 1px solid rgb(218, 211, 211);
  color: rgb(163, 153, 153);
}
.exportButton {
  height: 38px !important;
}
.uppercase-options .form-select-option {
  text-transform: uppercase;
}
.spinnerCentered {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 20px;
  /* Set the height of the container */
}
</style>
