<template>
    <div>
      <b-row class="match-height-container"> 
        <b-col  cols="3" class="chartContainer">
          <AmazonCardStatistic v-if="amazonChartValue.labels.length" :labels="amazonChartValue.labels"
            :data="amazonChartValue.spendsData" title="Spends" :summary="summaryData.spends" />
            </b-col>
        <b-col  cols="3" class="chartContainer">
          <AmazonCardStatistic v-if="amazonChartValue.labels.length" :labels="amazonChartValue.labels"
            :data="amazonChartValue.sellData" title="Sales" :summary="summaryData.sales" />
        </b-col>
        <b-col  cols="3" class="chartContainer">
          <AmazonCardStatistic v-if="amazonChartValue.labels.length" :labels="amazonChartValue.labels"
            :data="amazonChartValue.roasData" title="ROAS" :summary="summaryData.roas" />
        </b-col>
        <b-col  cols="3" class="chartContainer">
          <AmazonCardStatistic v-if="amazonChartValue.labels.length" :labels="amazonChartValue.labels"
            :data="amazonChartValue.conversionsData" title="Conversion Rate" :summary="summaryData.conversionRate" />
        </b-col>
      </b-row>
      <b-row class="match-height-container">
        <b-col  cols="3" class="chartContainer">
          <AmazonCardStatistic v-if="amazonChartValue.labels.length" :labels="amazonChartValue.labels"
            :data="amazonChartValue.impressionsData" title="Impressions" :summary="summaryData.impressions" />
       
        </b-col>
        <b-col  cols="3" class="chartContainer">
          <AmazonCardStatistic v-if="amazonChartValue.labels.length" :labels="amazonChartValue.labels"
            :data="amazonChartValue.clicksData  " title="Clicks" :summary="summaryData.clicks" />
        </b-col>
        <b-col  cols="3" class="chartContainer">
          <AmazonCardStatistic v-if="amazonChartValue.labels.length" :labels="amazonChartValue.labels"
            :data="amazonChartValue.CTRData" title="CTR" :summary="summaryData.ctr" />
        </b-col>
        <b-col  cols="3" class="chartContainer">
          <AmazonCardStatistic v-if="amazonChartValue.labels.length" :labels="amazonChartValue.labels"
            :data="amazonChartValue.cpcData" title="CPC" :summary="summaryData.costPerClick" />
        </b-col>
      </b-row>
    </div>
  </template>
  
  <script>
  import { BCard, BRow, BCol } from "bootstrap-vue";
  import CardStatisticsGroup from "../GoogleAds/CardStatisticsGroup.vue";
  import AmazonCardStatistic from "./AmazonCardStatistic.vue";
  
  export default {
    props: {
      data: {
        type: Object,
        default: () => {
          return {};
        },
      },
      iconCardType: { type: String },
      summaryData: {
        type: Object,
        default: () => {
          return {
            spends: 0,
            sales: 0,
            roas: 0,
            impressions: 0,
            conversionValue: 0,
            avg_cpm: 0,
            clicks:0,
            ctr:0,
          };
        },
      },
      chartData: {
        type: Array,
        default: () => {
          return [];
        },
      },
      businessType: { type: Array,
        default: () => {
          return [];
        } },
    },
    data() {
      return {
        iconType: 'Hello'
      };
    },
    computed: {
      amazonChartValue() {
        let data = this.chartData;
        let labels = [];
        let clicksData = [{ name: "Clicks", data: [] }];
        let spendsData = [{ name: "Spends", data: [] }];
        let roasData = [{ name: "ROAS", data: [] }];
        let sellData = [{ name: "Sales", data: [] }];
        let CTRData = [{ name: "CTR", data: [] }];
        let impressionsData = [{ name: "Impressions", data: [] }];
        let conversionsData = [{ name: "Conversion Rate", data: [] }];
        let cpcData = [{ name: "CPC", data: [] }];
  
        for (let i = 0; i < data.length; i++) {
          const dataObject = data[i];
          labels.push(dataObject.key);
          clicksData[0].data.push(dataObject.clicks);
          spendsData[0].data.push(dataObject.spends);
          roasData[0].data.push(dataObject.roas)
          sellData[0].data.push(dataObject.sales)
          CTRData[0].data.push(dataObject.ctr)
          impressionsData[0].data.push(dataObject.impressions);
          conversionsData[0].data.push(dataObject.conversionRate);
          cpcData[0].data.push(dataObject.costPerClick);
        }
        return {
          labels,
          clicksData,
          spendsData,
          roasData,
          sellData,
          CTRData,
          impressionsData,
          conversionsData,
          cpcData
        };
      },
    },
    components: {
      BCard,
      BRow,
      BCol,
      CardStatisticsGroup,
      AmazonCardStatistic,
    },
  };
  </script>
  <style>
  .chartContainer {
    padding: 6px !important;
  }
  </style>
  