<template>
  <div>
    <b-row v-if="client.status" class="content-header">
      <b-col class="mb-1" cols="10">
        <b-row class="breadcrumbs-top">
          <b-col cols="12">
            <h2
              class="content-header-title float-left pr-1 mb-0 text-white"
              style="text-transform: capitalize;"
            >
              <b-avatar
                class="mr-1"
                size="32"
                :src="client.logo"
                :text="avatarText(client.name)"
                :variant="client.logo ? `white` : `primary`"
              />
              {{ client.name }}
            </h2>
            <div class="breadcrumb-wrapper breadcrumb-container">
              <b-breadcrumb>
                <b-breadcrumb-item to="/">
                  <feather-icon
                    icon="HomeIcon"
                    size="16"
                    class="align-text-top text-white"
                  />
                </b-breadcrumb-item>
                <b-breadcrumb-item
                  v-for="item in breadcrumbs"
                  :key="item.text"
                  :active="item.active"
                  :to="item.to"
                  class="text-white"
                >
                  {{ item.text }}
                </b-breadcrumb-item>
              </b-breadcrumb>
            </div>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="2">
        <div style="float: right; width: 200px;">
          <b-button
            @click="sendSchedule(client._id)"
            :size="buttonSize"
            variant="primary"
          >
            <feather-icon icon="PlusIcon" size="12" /> Schedule a Trigger
          </b-button>
        </div>
      </b-col>
    </b-row>
    <!-- <b-alert :show="!client.status & !isReportDataLoading" variant="danger">
      <h4 class="alert-heading">Error fetching Client data</h4>
    </b-alert> -->
    <!-- DASHBOARD -->
    <div>
      <div v-if="client.status && client.integrations.amazon">
        <b-overlay
          class="dashboard-overlay"
          rounded="sm"
          :show="isReportDataLoading"
        >
        <!-- <div v-if="isReportDataLoading">
          <div
            style="display: flex; justify-content: center; align-items: center; margin-top: 15%;"
          >
            <Spinner />
          </div>
        </div> -->
        <div>
          <AmazonDateSelector
            :client="client._id"
            :slug="$route.params.slug"
            @fetchNewData="getReportData"
            @fetchSubCampaignNewData="getSubCampaignReportData"
            @fetchSubSearchCampaignNewData="getSubCatogorySearchViewReportData"
            @fetchAdTypeCampaignNewData="getAdTypeSearchViewReportData"
            @fetchBCGCampaignNewData="getBCGSearchViewReportData"
            @fetchMonthNewData="getMonthWiseData"
            @fetchKeyWordsNewData="getKeyWordPlannerReportData"
            @fetchDailyReportNewData="getDailyReportData"
            :isDisable="true"
            :chartSummaryData="overviewData"
          />
          <b-card class="tabs-container screenAlignment p-1 screenAmazonAlignmentMultiChannel" >
            <b-tabs fill class="multiAmazonchanneldashbpa">
              <b-tab
                title="Overview"
                active
                @click="changeTab('Graphs')"
              ></b-tab>
              <b-tab
                title="Planned Vs Achieved"
                @click="changeTab('plannedVsAchieved')"
              >
              </b-tab>
              <b-tab title="Daily Report" @click="changeTab('DailyReport')">
              </b-tab>
              <!-- <b-tab
                title="Primary Campaign Report"
                @click="changeTab('CampaignReport')"
              >
              </b-tab> -->
              <b-tab
              title="Campaign Report"
              @click="changeTab('SubCampaignReport')"
            >
            </b-tab>
            <b-tab
            title="Search Details View"
            @click="changeTab('SearchDetailsView')"
          >
          </b-tab>
              <b-tab
                title="Keyword Level Performance"
                @click="changeTab('KeywordLevelPerformance')"
              >
              </b-tab>
              <b-tab title="Month-Wise Report" @click="changeTab('MonthWise')">
              </b-tab>
            </b-tabs>
          </b-card>
          <!-- Graph Display -->
          <div
            style="display: grid; grid-template-columns: repeat(2, 1fr);column-gap: 25px;"
            v-if="!isProperLoad && this.tabName === 'Graphs'"
          >
            <div
              v-for="(graph, index) in graphs"
              :key="index"
              style="flex: 0 1 calc(50% - 1.2rem); min-width: 0; box-sizing: border-box;"
            >
              <AmazonGraphDisplay
                @deleteGraph="deleteGraph"
                :index="index"
                :chartData="graph"
                :data="metricData"
              />
            </div>
          </div>
          <AmazonOverviewCard 
            v-if="
              this.tabName === 'Graphs' &&
                client.status &&
                client.integrations.amazon &&
                chartDataAmazon.length > 0
            "
            :isDataLoading="isReportDataLoading"
            :summaryData="overviewData"
            :chartData="chartDataAmazon"
            :iconCardType="iconType"
            :businessType="client.businessType"
            mediumType="aAds"
          />
          <!-- Graph Display -->
          <b-row v-if="this.tabName === 'plannedVsAchieved'">
            <b-col cols="6" class="graph-card cardOrg1">
              <b-card class="card-tiny-line-stats" body-class="pb-50">
                <h6 class="text-xs text-center">
                  Spends
                  <!-- <feather-icon
                    size="15"
                    icon="InfoIcon"
                    color="grey"
                    style="margin-bottom: 3px; cursor: pointer; margin-left: 3px"
                  /> -->
                </h6>
                <h2 class="font-weight-bolder text-center app-title lg">
                  <!-- {{ this.orgStores == null ? 0 : this.orgStores }} -->
                </h2>
                <!-- chart -->
                <div
                  style="justify-content: center; align-items: center;  margin-left: auto; margin-right: auto; "
                >
                  <apexchart
                    type="bar"
                    height="290"
                    :options="optionsSpends"
                    :series="seriesSpendsData"
                  ></apexchart>
                </div>
                <div style="text-align: center;">
                </div>
              </b-card>
            </b-col>
            <b-col cols="6" class="graph-card cardOrg1">
              <b-card class="card-tiny-line-stats" body-class="pb-50">
                <h6 class="text-xs text-center">
                  Sales
                  <!-- <feather-icon
                    size="15"
                    icon="InfoIcon"
                    color="grey"
                    style="margin-bottom: 3px; cursor: pointer; margin-left: 3px"
                  /> -->
                </h6>
                <h2 class="font-weight-bolder text-center app-title lg">
                  <!-- {{ this.orgStores == null ? 0 : this.orgStores }} -->
                </h2>
                <!-- chart -->
                <div
                  style="justify-content: center; align-items: center;  margin-left: auto; margin-right: auto; "
                >
                  <apexchart
                    type="bar"
                    height="290"
                    :options="optionsSales"
                    :series="seriesSalesShopData"
                  ></apexchart>
                </div>
                <div style="text-align: center;">
                </div>
              </b-card>
            </b-col>
          </b-row>
          <!-- <template v-if="this.tabName === 'plannedVsAchieved'">
            <b-row>
              <b-col cols="12">
                <b-card>
                  <div style="display: flex; justify-content: space-between">
                    <div>
                      <b-card-title>
                        <h3 class="app-title mdsm">Planned Vs Achieved</h3>
                      </b-card-title>
                    </div>
                    <div>
                      <b-button variant="primary" @click="openTargetVsAchivedAmazon">
                       Add
                      </b-button>
                      </div>
                    </div>
                  <div class="plannedVsAchieved">
                    <table style="border:2px solid black">
                      <tr>
                        <th>Category</th>
                        <th>Planned vs Actual</th>
                        <th>Imp</th>
                        <th>Clicks</th>
                        <th>Spend</th>
                        <th>Order</th>
                        <th>Sales</th>
                        <th>CTR</th>
                        <th>CPC</th>
                        <th>CVR</th>
                        <th>AOV</th>
                        <th>ROAS</th>
                        <th>ACOS</th>
                      </tr>
                    <template v-for="categoryData in tableData">
                     <div style="padding:4px"></div>
                      <tr v-for="(row, rowIndex) in categoryData.rows" :key="categoryData.category + '-' + rowIndex">
                        <th v-if="rowIndex === 0" :rowspan="3">{{ categoryData.category }}</th>
                        <td style="font-weight:bold">{{ row.type }}</td>
                        <td>{{ row.imp }}</td>
                        <td>{{ row.clicks }}</td>
                        <td>{{ row.spend }}</td>
                        <td>{{ row.order }}</td>
                        <td>{{ row.sales }}</td>
                        <td>{{ row.ctr }}</td>
                        <td>{{ row.cpc }}</td>
                        <td>{{ row.cvr }}</td>
                        <td>{{ row.aov }}</td>
                        <td>{{ row.roas }}</td>
                        <td>{{ row.acos }}</td>
                      </tr>
                    </template>
                    </table>
                  </div>
                </b-card>
              </b-col>
            </b-row>
          </template> -->
 
          <!-- <data-table
            v-if="this.tabName === 'plannedVsAchieved'"
            title="Planned Vs Achieved"
            type="amazonPlannedVsAchieved"
            exportsType="amazonPlannedVsAchieved"
            :chartData="amazonPlannedVsAchieved"
          /> -->
          <data-table
            v-if="this.tabName === 'DailyReport'"
            exportsType="amazonDaily"
            title="Daily Report"
            type="amazon"
            :subCampaign="intialSubOptions"
            :chartData="amazonData"
            @subAmazonMonthCatergoryChange="getDailySubCategory"
            @subAmazonMonthSubCatergoryChange="getDailySubSubCategory"
            @bCGAmazonMonthCatergoryChange="getDailyhBcg"
            @subAmazonMonthAdTypeCatergoryChange="getDailyAdType"
            :isTableDataLoad="isDailyReportFetched"
          />
          <data-table
            v-if="this.tabName === 'CampaignReport'"
            title="Campaign Report"
             exportsType="amazonCampaign"
            type="amazonCampaign"
            :chartData="amazonCampaignData"
          />
            <AmazonSearchView v-if="this.tabName === 'SearchDetailsView'"
            @subSearchCatergoryChange="getSearchSubCategory"
            @subSearchSubCatergoryChange="getSearchSubSubCategory"
            @bCGSearchCatergoryChange="getSearchBcg"
            @subSearchAdTypeCatergoryChange="getSearchAdType"
          :subCampaign="intialSubOptions"
          title="Search Details View"
          exportsType="amazonCampaign"
          type="AmazonSubCampaignReport"
          :chartSubCategoryData="subSearchCampaignDataAmazon"
          :isDataLoadingForSub="isSubCampaignSearchReportDataLoading"
          :isDataAdTypeLoadingForSub="isAdTypeSearchReportDataLoading"
          :isDataBCGLoadingForSub="isBCGSearchReportDataLoading"
          :chartAdTypeData="adTypeSearchCampaignDataAmazon"
          :chartBCGData="bcgSearchCampaignDataAmazon"
        />
            <!-- <b-col cols="12">
                <b-card class="tabs-container screenAlignment p-1 screenAmazonAlignmentMultiChannel" >
                  <b-tabs fill class="multiAmazonchanneldashbpa">
                    <b-tab title="Sub Category"
                    @click="changeTab('SubCategory')">

                    </b-tab>
                    <b-tab title="Ad Type"
                    @click="changeTab('AdType')">

                    </b-tab>
                    <b-tab title="BCG"
                    @click="changeTab('BCG')">

                    </b-tab >
                    </b-tabs>
                    </b-card>
            </b-col> -->
   
          <AmazonSubCampaignTable
            @subCatergoryChange="getSubCategory"
              @subSubCatergoryChange="getSubSubCategory"
                @bCGCatergoryChange="getbcg"
                  @subAdTypeCatergoryChange="getAdType"
          v-if="this.tabName === 'SubCampaignReport'"
          :subCampaign="intialSubOptions"
          title="Campaign Report"
          exportsType="amazonCampaign"
          type="AmazonSubCampaignReport"
          :chartData="subCampaignDataAmazon"
          :isDataLoadingForSub="isSubCampaignReportDataLoading"
        />
          <b-row
            v-if="isKeyWordLoad && this.tabName === 'KeywordLevelPerformance'"
          >
            <b-col cols="12">
              <b-card class="keyword-planner" style="padding: 5px;">
                <div>
                  <div class="conditional-header">
                    <b-card-title>
                      <h5 class="app-title mdsm mt-2">Keyword Level Performance</h5>
                    </b-card-title>
                    <div style="display: flex; justify-content: space-between;gap: 10px;">
                      <div
                        style="display: flex; justify-content: space-between;gap: 10px;"
                      >
                        <div style="width: 150px;">
                          <b-form-group>
                            <label for="client-name">Sub Category Type:</label>
                            <b-form-select
                               @input="getSubKeyCatergoryChange"
                              class="uppercase-options"
                              v-model="selectedKeySubCategoriesType"
                              :options="this.intialSubOptions && this.intialSubOptions.sub_category"
                            />
                          </b-form-group>
                        </div>
                        <div style="width: 170px;">
                          <b-form-group>
                            <label for="client-name">Sub Sub Category Type:</label>
                            <b-form-select
                                @input="getKeySubSubCatergoryChange"
                              class="uppercase-options"
                              v-model="selectedKeySubSubCategoriesType"
                              :options="
                                this.intialSubOptions && this.intialSubOptions.sub_sub_category
                              "
                            />
                          </b-form-group>
                        </div>
                        <div style="width: 130px;">
                          <b-form-group>
                            <label for="client-name">BCG:</label>
                            <b-form-select
                            @input="getKeyBCGCatergoryChange"
                              class="uppercase-options"
                              v-model="selectedKeyBcg"
                              :options="this.intialSubOptions && this.intialSubOptions.bcg"
                            />
                          </b-form-group>
                        </div>
                        <div style="width: 130px;">
                          <b-form-group>
                            <label for="client-name">Ad Type:</label>
                            <b-form-select
                              @input="getKeyAdTypeCatergoryChange"
                              class="uppercase-options"
                              v-model="selectedKeyAdType"
                              :options="this.intialSubOptions && this.intialSubOptions.ad_type"
                            />
                          </b-form-group>
                        </div>
                        <div style="margin-top:24px">
                          <input
                          style="width: 155px !important;"
                          type="text"
                          v-model="searchTerm"
                          placeholder="Search by keyword."
                        />
                        </div>
                      </div>
                      <!-- <input
                        type="text"
                        style="width: 300px; margin-top:23px"
                        v-model="searchTerm"
                        placeholder="Campaign name.."
                      /> -->
                      <div></div>
                      <!-- <b-button  @click="exportData" class="exportButton"  size="sm" variant="primary" style=" margin-top:23px"
                        ><span style="font-weight: bold;">
                          Export Data
                          <feather-icon
                            icon="DownloadIcon"
                            size="15"
                            style="margin-left: 5px;"/></span
                      ></b-button> -->
                    </div>
                   
                  </div>
                </div>
                <MultiColorTable
                  :PaidData="keywordPlannerData"
                  TableType="keywordPlanner"
                  :SearchData="searchTerm"
                  :isKeywordDataFetched="isKeywordDataFetched"
                />
              </b-card>
            </b-col>
          </b-row>

          <data-table
            v-if="this.tabName === 'MonthWise'"
            exportsType="amazonMonthWise"
            title="Month-Wise Report Data"
            type="monthWiseDataAmazonDashboard"
            :subCampaign="intialSubOptions"
            :chartData="monthWiseDataAmazon"
            @subMonthCatergoryChange="getMonthSubCategory"
              @subMonthSubCatergoryChange="getMonthSubSubCategory"
                @bCGMonthCatergoryChange="getMonthbcg"
                  @subMonthAdTypeCatergoryChange="getMonthAdType"
                  :isTableDataLoad="isMonthWiseDataLoads"
          />
        </div>

        <!-- Graph Selector -->
        <!-- <graph-selector @graphAdded="graphAdded" :metrics="metricData.data" /> -->
        <!-- <AmazonGraphSelector @graphAdded="graphAdded" :metrics="metricData" /> -->
        <!-- Graph Selector -->

        <!-- <div v-if="!isReportDataLoading">
            <graph-display
              @deleteGraph="deleteGraph"
              :index="index"
              :chartData="graph"
              :data="charts.data"
              v-for="(graph, index) in graphs"
              :key="index"
            />
          </div> -->

        <!-- <filters
            v-if="client._id"
            :clientID="client._id"
            @filterReportData="filterReportData"
          /> -->
        </b-overlay>  
      </div>
    </div>
    <b-modal
    v-model="isTargetVsAchievedAmazon"
    id="showShopNameModal"
    hide-footer
    hide-header
    centered
    size="lg"
  >
    <b-card-text>
      <div style="display: flex; justify-content:space-between; padding:10px">
        <h5 class="app-title mdsm">
          Add Targeted Value:
        </h5>

        <div class="header text-right">
          <feather-icon
            style="cursor: pointer"
            @click="closeTargetVsAchivedAmazon"
            class="Xicon"
            icon="XIcon"
          ></feather-icon>
        </div>
      </div>
    </b-card-text>
  </b-modal>

    <transition name="modal">
      <div class="modal-mask" v-if="openScheduleModal">
        <TriggerModalAll
          @close="closeScheduleClientPopUp"
          :clientID="client._id"
          @closeScheduleClientPopUp="closeScheduleClientPopUp"
          :scheduleModal="this.openScheduleModal"
        />
      </div>
    </transition>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCardTitle,
  BFormCheckbox,
  BBreadcrumb,
  BBreadcrumbItem,
  BAlert,
  BLink,
  BAvatar,
  BCard,
  BButton,
  BOverlay,
  BTab,
  BTabs,
  BSpinner,
  BModal,
  BCardText,
  BFormSelect,
  BFormGroup
} from "bootstrap-vue";
import MultiColorTable from "../../../Client/MultiColorTable.vue";
import AmazonGraphSelector from "./AmazonGraphSelector.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import DataTable from "../GoogleAds/DataTable.vue";
import GraphSelector from "../GoogleAds/GraphSelector.vue";
import GraphDisplay from "../GoogleAds/GraphDisplay.vue";
import DateSelector from "../GoogleAds/DateSelector.vue";
import Filters from "../GoogleAds/Filters.vue";
import VueApexCharts from "vue-apexcharts";
import axios from "axios";
import { getUserToken } from "@/auth/utils";
import AmazonOverviewCard from "./AmazonOverviewCard.vue";
import AmazonDateSelector from "./AmazonDateSelector.vue";
import TriggerModalAll from "@/components/client/TriggerModalAll.vue";
import AmazonGraphDisplay from "./AmazonGraphDisplay.vue";
import Spinner from "./../../../../components/client/Spinner.vue";
import client from "@/router/client";
import AmazonSearchView from './AmazonSearchView.vue'
import AmazonSubCampaignTable from "./AmazonSubCampaignTable.vue";
export default {
  data() {
    return {
      selectedStartDate:"",
      selectedEndDate:"",
      startDateProjectName:"",
      endDateProjectName:"",
      isKeywordDataFetched:false,
      isDailyReportFetched:false,
      tableHeaders: [],
      selectedKeySubCategoriesType: "all",
      selectedKeySubSubCategoriesType: "all",
      selectedKeyBcg: "all",
      selectedKeyAdType: "all",
      selectedDailySubCategoriesType: "all",
      selectedDailySubSubCategoriesType: "all",
      selectedDailyBcg: "all",
      selectedDailyAdType: "all",
      isBCGSearchReportDataLoading:false,
      isAdTypeSearchReportDataLoading:false,
      isTargetVsAchievedAmazon:false,
      tableData: [
        {
          category: "Phone",
          rows: [
            {
              type: "Planned",
              imp: 10,
              clicks: 10,
              spend: 10,
              order: 10,
              sales: 10,
              ctr: 10,
              cpc: 10,
              cvr: 10,
              aov: 10,
              roas: 10,
              acos: 10,
            },
            {
              type: "Actual",
              imp: 20,
              clicks: 20,
              spend: 20,
              order: 20,
              sales: 20,
              ctr: 20,
              cpc: 20,
              cvr: 20,
              aov: 20,
              roas: 20,
              acos: 20,
            },
            {
              type: "Actual to Planned Ratio(%)",
              imp: 30,
              clicks: 30,
              spend: 30,
              order: 30,
              sales: 30,
              ctr: 30,
              cpc: 30,
              cvr: 30,
              aov: 30,
              roas: 30,
              acos: 30,
            },
          ],
        },
        {
          category: "Laptop",
          rows: [
            {
              type: "Planned",
              imp: 15,
              clicks: 15,
              spend: 15,
              order: 15,
              sales: 15,
              ctr: 15,
              cpc: 15,
              cvr: 15,
              aov: 15,
              roas: 15,
              acos: 15,
            },
            {
              type: "Actual",
              imp: 25,
              clicks: 25,
              spend: 25,
              order: 25,
              sales: 25,
              ctr: 25,
              cpc: 25,
              cvr: 25,
              aov: 25,
              roas: 25,
              acos: 25,
            },
            {
              type: "Actual to Planned Ratio(%)",
              imp: 35,
              clicks: 35,
              spend: 35,
              order: 35,
              sales: 35,
              ctr: 35,
              cpc: 35,
              cvr: 35,
              aov: 35,
              roas: 35,
              acos: 35,
            },
          ],
        },
        {
          category: "Tablet",
          rows: [
            {
              type: "Planned",
              imp: 12,
              clicks: 12,
              spend: 12,
              order: 12,
              sales: 12,
              ctr: 12,
              cpc: 12,
              cvr: 12,
              aov: 12,
              roas: 12,
              acos: 12,
            },
            {
              type: "Actual",
              imp: 22,
              clicks: 22,
              spend: 22,
              order: 22,
              sales: 22,
              ctr: 22,
              cpc: 22,
              cvr: 22,
              aov: 22,
              roas: 22,
              acos: 22,
            },
            {
              type: "Actual to Planned Ratio(%)",
              imp: 32,
              clicks: 32,
              spend: 32,
              order: 32,
              sales: 32,
              ctr: 32,
              cpc: 32,
              cvr: 32,
              aov: 32,
              roas: 32,
              acos: 32,
            },
          ],
        },
      ],
      subCategory:"all",
      subSubCategory:"all",
      bcg:"all",
      adType:"all",
      subMonthSearchCategory:"all",
      subMonthSearchSubCategory:"all",
      bcgMonthSearch:"all",
      adMonthSearchType:"all",
      subSearchCategory:"all",
      subSearchSubCategory:"all",
      isMonthWiseDataLoads:false,
      bcgSearch:"all",
      adSearchType:"all",
      insubCategory:"",
      insubSubCategory:"",
      inbcg:"",
      inadType:"",
      intialSubOptions:null,
      plannedSpends: [],
      plannedSales: [],
      achievedSpends: [],
      achievedSales: [],
      monthsName: [],
      tooltipText: "Change over previous month",
      monthWiseDataAmazon: [],
      keywordPlannerData: [],
      isSubCampaignReportDataLoading:false,
      isSubCampaignSearchReportDataLoading:false,
      isKeyWordLoad: false,
      searchTerm: "",
      showModal: false,
      openScheduleModal: false,
      subCampaignDataAmazon:[],
      subSearchCampaignDataAmazon:{},
      adTypeSearchCampaignDataAmazon:{},
      bcgSearchCampaignDataAmazon:{},
      isTriggerModal: false,
      iconType: "",
      client: {
        status: false,
        integrations: {
          googleAds: null
        },
        googleAdsCustomerID: null
      },
      errorMessage: "",
      breadcrumbs: [
        {
          text: "Overview",
          to: "/dashboard"
        }
      ],
      isReportDataLoading: false,
      amazonData: [],
      amazonCampaignData: [],
      chartDataAmazon: [],
      overviewData: {},
      charts: {
        data: [],
        totalData: {}
      },
      graphs: [],
      metricData: [],
      amazonPlannedVsAchieved: [],
      currentDateRange: "",
      isProperLoad: false,
      tabName: "",
      seriesSpendsData: [
        {
          name: "Planned",
          data: [44, 55, 57, 56, 61, 58, 63, 60, 66]
        },
        {
          name: "Achieved",
          data: [76, 85, 101, 98, 87, 105, 91, 114, 94]
        }
      ],
      seriesSalesShopData: [
        {
          name: "Planned",
          data: [44, 55, 57, 56, 61, 58, 63, 60, 66]
        },
        {
          name: "Achieved",
          data: [76, 85, 101, 98, 87, 105, 91, 114, 94]
        }
      ],
      optionsSpends: {
        chart: {
          type: "bar",
          height: 250,
          toolbar: {
            show: false
          },
          zoom: {
            enabled: false
          }
        },
        plotOptions: {
          bar: {
            horizontal: false
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          show: true
        },
        tooltip: {
          shared: true,
          intersect: false
        },
        xaxis: {
          categories: ["Total Views", "Map Views", "Search Views"]
        }
      },
      optionsSales: {
        chart: {
          type: "bar",
          height: 250,
          toolbar: {
            show: false
          },
          zoom: {
            enabled: false
          }
        },
        plotOptions: {
          bar: {
            horizontal: false
          }
        },
        colors: ["#662E9B", "#F9C80E"],
        dataLabels: {
          enabled: false
        },
        stroke: {
          show: true
        },
        tooltip: {
          shared: true,
          intersect: false
        },
        xaxis: {
          categories: ["Total Views", "Map Views", "Search Views"]
        }
      }
    };
  },
  props: {
    buttonSize: {
      type: String
    },
    facebookDashBoardPage: {
      type: String
    },
    googleDashBoardPage: {
      type: String
    }
  },
  components: {
    BModal,
    BCardText,
    Spinner,
    apexchart: VueApexCharts,
    BTab,
    BTabs,
    MultiColorTable,
    AmazonGraphSelector,
    BRow,
    BCol,
    BCardTitle,
    BFormCheckbox,
    BBreadcrumb,
    BBreadcrumbItem,
    BAlert,
    BLink,
    BAvatar,
    BCard,
    BButton,
    AmazonOverviewCard,
    DataTable,
    BOverlay,
    GraphSelector,
    GraphDisplay,
    DateSelector,
    Filters,
    AmazonOverviewCard,
    AmazonDateSelector,
    TriggerModalAll,
    AmazonGraphDisplay,
    BSpinner,
    AmazonSubCampaignTable,
    AmazonSearchView,
    BFormSelect,
    BFormGroup
  },
  methods: {
    getDailySubCategory(val){
      this.selectedDailySubCategoriesType = ""
      this.selectedDailySubCategoriesType = val
    },
    getDailySubSubCategory(val){
      this.selectedDailySubSubCategoriesType = ""
      this.selectedDailySubSubCategoriesType = val
    },
    getDailyhBcg(val){
      this.selectedDailyBcg = ""
      this.selectedDailyBcg = val
    },
    getDailyAdType(val){
      this.selectedDailyAdType = ""
      this.selectedDailyAdType = val
    },
    getSubKeyCatergoryChange(val){
      this.selectedKeySubCategoriesType = ""
      this.selectedKeySubCategoriesType = val
    },
    getKeySubSubCatergoryChange(val){
      this.selectedKeySubSubCategoriesType = ""
      this.selectedKeySubSubCategoriesType = val
    },
    getKeyBCGCatergoryChange(val){
      this.selectedKeyBcg = ""
      this.selectedKeyBcg = val
    },
    getKeyAdTypeCatergoryChange(val){
      this.selectedKeyAdType = ""
      this.selectedKeyAdType = val
    },
    closeTargetVsAchivedAmazon() {
      this.isTargetVsAchievedAmazon = false;
    },
    openTargetVsAchivedAmazon() {
      this.isTargetVsAchievedAmazon = true;
    },
    getSearchSubCategory(val){
      this.subSearchCategory = ""
      this.subSearchCategory = val
    },
    getSearchSubSubCategory(val){
      this.subSearchSubCategory = ""
      this.subSearchSubCategory = val
    },
    getSearchBcg(val){
      this.bcgSearch = ""
      this.bcgSearch = val
    },
    getSearchAdType(val){
      this.adSearchType = ""
      this.adSearchType = val
    },
    getMonthSubCategory(val){
      this.subMonthSearchCategory = ""
      this.subMonthSearchCategory = val
    },
    getMonthSubSubCategory(val){
      this.subMonthSearchSubCategory = ""
      this.subMonthSearchSubCategory = val
    },
    getMonthbcg(val){
      this.bcgMonthSearch = ""
      this.bcgMonthSearch = val
    },
    getMonthAdType(val){
      this.adMonthSearchType = ""
      this.adMonthSearchType = val
    },
    getSubCategory(val){
      this.subCategory = ""
      this.subCategory = val
    },
    getSubSubCategory(val){
      this.subSubCategory = ""
      this.subSubCategory = val
    },
    getbcg(val){
      this.bcg = ""
      this.bcg = val
    },
    getAdType(val){
      this.adType = ""
      this.adType = val
    },
    getFetchSubOptions(){
      this.intialSubOptions = {}
      axios.get(`${process.env.VUE_APP_SERVER_URL}/api/amazon/amazon-option?clientID=${this.client._id}`, {
        headers: {
          Authorization: `Bearer ${getUserToken()}`,
          "Content-type": "application/json",
        },
      })
        .then((response) => {
         this.intialSubOptions = response.data
         this.insubCategory = this.intialSubOptions.sub_category[0]
         this.insubSubCategory = this.intialSubOptions.sub_sub_category[0]
         this.inbcg =  this.intialSubOptions.bcg[0]
         this.inadType = this.intialSubOptions.ad_type[0]
        })
        .catch((error) => {
          console.log(error);
      });
    },
    updateData() {
      const commonChartOptions = {
        optionsSpends: {
          chart: {
            type: "bar",
            height: 250,
            toolbar: {
              show: false
            },
            zoom: {
              enabled: false
            }
          },
          plotOptions: {
            bar: {
              horizontal: false
            }
          },
          stroke: {
            show: true
          },
          tooltip: {
            shared: true,
            intersect: false
          }
        }
      };
      this.optionsSales = {
        ...commonChartOptions,
        colors: ["#662E9B", "#F9C80E"],
        chart: {
          type: "bar",
          height: 250,
          toolbar: {
            show: false
          },
          zoom: {
            enabled: false
          }
        },
        dataLabels: {
          enabled: false
        },
        xaxis: {
          categories: this.monthsName
        }
      };
      this.optionsSpends = {
        ...commonChartOptions,
        chart: {
          type: "bar",
          height: 250,
          toolbar: {
            show: false
          },
          zoom: {
            enabled: false
          }
        },
        dataLabels: {
          enabled: false
        },
        xaxis: {
          categories: this.monthsName
        }
      };

      this.seriesSpendsData = [
        {
          name: "Planned",
          data: this.plannedSpends
        },
        {
          name: "Achieved",
          data: this.achievedSpends
        }
      ];
      this.seriesSalesShopData = [
        {
          name: "Planned",
          data: this.plannedSales
        },
        {
          name: "Achieved",
          data: this.achievedSales
        }
      ];
    },
    changeTab(name) {
      this.tabName = name;
      if (this.tabName === 'SubCampaignReport') {
        this.subCategory = "all"
          this.subSubCategory = "all"
            this.bcg = "all"
              this.adType = "all"
        this.getSubCampaignReportData()
      }
      if (this.tabName === 'SearchDetailsView') {
        this.subSearchCategory = "all"
          this.subSearchSubCategory = "all"
            this.bcgSearch = "all"
              this.adSearchType = "all"
        this.getSubCatogorySearchViewReportData()
        this.getAdTypeSearchViewReportData()
        this.getBCGSearchViewReportData()
      }
      if(this.tabName === 'plannedVsAchieved'){
        this.getPlannedVsAcheivedData();
      }
      if (this.tabName === 'KeywordLevelPerformance') {
        this.selectedKeySubCategoriesType = "all"
          this.selectedKeySubSubCategoriesType = "all"
            this.selectedKeyBcg = "all"
              this.selectedKeyAdType = "all"
        this.getKeyWordPlannerReportData();
      }
      if(this.tabName === 'MonthWise'){
        this.subMonthSearchCategory = "all"
          this.subMonthSearchSubCategory = "all"
            this.bcgMonthSearch = "all"
              this.adMonthSearchType = "all"
        this.getMonthWiseData();
      }
      if(this.tabName === 'DailyReport'){
        this.selectedDailySubCategoriesType = "all"
          this.selectedDailySubSubCategoriesType = "all"
            this.selectedDailyBcg = "all"
              this.selectedDailyAdType = "all"
        this.getDailyReportData();
      }
    },
    getPlannedVsAcheivedData() {
      this.plannedSpends = [];
      this.plannedSales = [];
      this.achievedSpends = [];
      this.achievedSales = [];
      this.monthsName = [];
      axios
        .get(
          `${process.env.VUE_APP_SERVER_URL}/api/amazon/plannedVsAchieved?clientID=${this.client._id}`,
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`
            }
          }
        )
        .then(response => {
          let originalData = response.data;
          for (const month in originalData) {
            if (originalData.hasOwnProperty(month)) {
              const item = originalData[month];
              this.monthsName.push(month);
              this.achievedSales.push(item.achieved.sales);
              this.achievedSpends.push(item.achieved.spends);
              this.plannedSales.push(item.plannedReport?.planned_sales || 0);
              this.plannedSpends.push(item.plannedReport?.planned_spends || 0);
            }
          }
          this.updateData();
        })
        .catch(error => {
          console.log(error);
        });
    },
    getMonthWiseData() {
      this.monthWiseDataAmazon = [];
      this.isMonthWiseDataLoads = true
      let subCategoryIntial = this.subMonthSearchCategory.length === 0 ? "all" : this.subMonthSearchCategory;
      let subSubCategoryIntial = this.subMonthSearchSubCategory.length === 0 ? "all" : this.subMonthSearchSubCategory;
      let subBCGIntial = this.bcgMonthSearch.length === 0 ? "all" : this.bcgMonthSearch;
      let adType = this.adMonthSearchType.length === 0 ? "all" : this.adMonthSearchType;
      axios
        .get(
          `${process.env.VUE_APP_SERVER_URL}/api/amazon/month-wise-report?clientID=${this.client._id}&sub_category=${subCategoryIntial}&sub_sub_category=${subSubCategoryIntial}&bcg=${subBCGIntial}&ad_type=${adType}`,
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`
            }
          }
        )
        .then(response => {
          let originalData = response.data;
          for (const month in originalData) {
            if (originalData.hasOwnProperty(month)) {
              const item = originalData[month];
              this.monthWiseDataAmazon.push({
                month,
                ...item
              });
            }
          }
          this.isMonthWiseDataLoads = false
        })
        .catch(error => {
          this.isMonthWiseDataLoads = false
          console.log(error);
        });
    },
    updateGraph(graphs) {
      axios
        .post(
          `${process.env.VUE_APP_SERVER_URL}/api/client/amazon/add-graph`,
          { clientID: this.client._id, graphs },
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`
            }
          }
        )
        .then(response => {
          this.showToast("Updated", "Graph updated successfully!", "success");
        })
        .catch(error => {
          console.log(error.response);
          this.showToast("Error", "Error updating the graph", "danger");
        });
    },
    graphAdded(graphData) {
      this.graphs.push(graphData);
      this.updateGraph(this.graphs);
    },
    deleteGraph(index) {
      this.graphs.splice(index, 1);
      this.updateGraph(this.graphs);
    },
    sendSchedule() {
      this.clientID = this.client._id;
      this.openScheduleModal = true;
    },
    closeScheduleClientPopUp() {
      this.openScheduleModal = false;
    },
    showToast(title, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon: variant == "success" ? "CheckCircleIcon" : "AlertCircleIcon",
          text,
          variant
        }
      });
    },
    avatarText(value) {
      if (!value) return "";
      const nameArray = value.split(" ");
      return nameArray.map(word => word.charAt(0).toUpperCase()).join("");
    },
    getClient() {
      const slug = this.$route.params.slug;
      axios
        .get(`${process.env.VUE_APP_SERVER_URL}/api/client/${slug}`, {
          headers: {
            Authorization: `Bearer ${getUserToken()}`
          }
        })
        .then(response => {
          this.client = { ...response.data.client, status: true };
          this.graphs = response.data.client.amazonGraphs
            ? response.data.client.amazonGraphs
            : [];
          this.breadcrumbs.push({
            text: response.data.client.name,
            to:`/channels-dashboard/${response.data.client.slug}`,
            active: false
          });
          this.breadcrumbs.push({
            text: "Amazon Ads Dashboard",
            to: `/client/${response.data.client.slug}/dashboard/amazon-ads`,
            active: true
          });

          if (
            response.data.client &&
            response.data.client.integrations.amazon
          ) {
            this.getFetchSubOptions();
            this.getReportData();
            // this.getMonthWiseData();
            // this.getPlannedVsAcheivedData();
            setTimeout(() =>{
              this.getSubCampaignReportData();
            
            },1000)
          }
        })
        .catch(error => {
          console.log(error.response.data);
          this.client.status = false;
          this.errorMessage = error.response.data.error;
        });
    },
    getReportData(
      dateRange,
      campaignType = null,
      campaigns = null,
      adGroups = null,
      startDate = null,
      endDate = null
    ) {
      this.isReportDataLoading = true;
      this.isProperLoad = true;
      if (!dateRange) {
        dateRange = "LAST_7_DAYS";
      }
      this.selectedStartDate = startDate
      this.selectedEndDate = endDate
      this.currentDateRange = dateRange;
      let additionalQueryParams = ``;
      if (!startDate && !endDate) {
         this.startDateProjectName = " "
        this.endDateProjectName = " "
        const today = new Date();
        const prevWeek = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000);
        const yesterday = new Date(today.getTime() - 1 * 24 * 60 * 60 * 1000);
        const formattedStartDate = prevWeek.toISOString().split("T")[0];
        const formattedEndDate = yesterday.toISOString().split("T")[0];
        this.startDateProjectName = formattedStartDate
        this.endDateProjectName = formattedEndDate 
        additionalQueryParams = `${additionalQueryParams}&startDate=${formattedStartDate}&endDate=${formattedEndDate}`;
      }
      if (startDate && endDate) {
        additionalQueryParams =
          additionalQueryParams + `&startDate=${startDate}&endDate=${endDate}`;
          this.startDateProjectName = startDate
          this.endDateProjectName = endDate
      }
      this.chartDataAmazon = [];
      this.metricData = [];
      this.amazonData = [];
      this.amazonPlannedVsAchieved = [];
      this.keywordPlannerData = [];
      this.isKeyWordLoad = false;
      axios
        .get(
          `${process.env.VUE_APP_SERVER_URL}/api/amazon/get-report?clientID=${this.client._id}${additionalQueryParams}`,
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`
            }
          }
        )
        .then(response => {
          this.isReportDataLoading = false;
          // this.amazonData = response.data.formattedByDate.charts.dataFormatted;
          this.amazonCampaignData = response.data.formattedByCampaigns;
          this.chartDataAmazon = response.data.formattedByDate.charts.data;
          this.overviewData = response.data.formattedByDate.charts.totalData;
          this.metricData = response.data.formattedByDate.charts.data;
          this.keywordPlannerData = response.data.formattedBySearchTerm;
          this.isKeyWordLoad = true;
          this.amazonPlannedVsAchieved =
            response.data.formattedByDate.charts.plannedVsAchieved;
          this.isProperLoad = false;
        })
        .catch(error => {
          this.isReportDataLoading = false;
          this.isProperLoad = false;
          console.log(error.response.data);
          let errorText =
            error.response.data && error.response.data.error
              ? error.response.data.error
              : "Unknown error occured";
          this.showToast("Error", errorText, "danger");
        });
    },
    getDailyReportData(
      dateRange,
      campaignType = null,
      campaigns = null,
      adGroups = null,
      startDate = null,
      endDate = null
    ) {
      this.isDailyReportFetched = true;
      if (!dateRange) {
        dateRange = "LAST_7_DAYS";
      }
      this.currentDateRange = dateRange;
      let additionalQueryParams = ``;
      if (!this.selectedStartDate && !this.selectedEndDate) {
        this.startDateProjectName = " "
        this.endDateProjectName = " "
        const today = new Date();
        const prevWeek = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000);
        const yesterday = new Date(today.getTime() - 1 * 24 * 60 * 60 * 1000);
        const formattedStartDate = prevWeek.toISOString().split("T")[0];
        const formattedEndDate = yesterday.toISOString().split("T")[0];
        additionalQueryParams = `${additionalQueryParams}&startDate=${formattedStartDate}&endDate=${formattedEndDate}`;
        this.startDateProjectName = formattedStartDate
        this.endDateProjectName = formattedEndDate
      }
      if (this.selectedStartDate && this.selectedEndDate) {
        additionalQueryParams =
          additionalQueryParams + `&startDate=${this.selectedStartDate}&endDate=${this.selectedEndDate}`;
          this.startDateProjectName = " "
        this.endDateProjectName = " "
          this.startDateProjectName = startDate
        this.endDateProjectName = endDate
      }
      this.amazonData = [];
      let subCategoryIntial = this.selectedDailySubCategoriesType.length === 0 ? "all" : this.selectedDailySubCategoriesType;
      let subSubCategoryIntial = this.selectedDailySubSubCategoriesType.length === 0 ? "all" : this.selectedDailySubSubCategoriesType;
      let subBCGIntial = this.selectedDailyBcg.length === 0 ? "all" : this.selectedDailyBcg;
      let adType = this.selectedDailyAdType.length === 0 ? "all" : this.selectedDailyAdType;
      axios
        .get(
          `${process.env.VUE_APP_SERVER_URL}/api/amazon/get-datewise-report?clientID=${this.client._id}${additionalQueryParams}&sub_category=${subCategoryIntial}&sub_sub_category=${subSubCategoryIntial}&bcg=${subBCGIntial}&ad_type=${adType}`,
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`
            }
          }
        )
        .then(response => {
          this.amazonData = response.data
          this.isDailyReportFetched = false;
        })
        .catch(error => {
          this.isDailyReportFetched = false;
          console.log(error.response.data);
        });
    },
    getKeyWordPlannerReportData(
      dateRange,
      campaignType = null,
      campaigns = null,
      adGroups = null,
      startDate = null,
      endDate = null
    ) {
      this.isKeywordDataFetched = true;
      if (!dateRange) {
        dateRange = "LAST_7_DAYS";
      }
      this.currentDateRange = dateRange;
      let additionalQueryParams = ``;
      if (!this.selectedStartDate && !this.selectedEndDate) {
        this.startDateProjectName = " "
        this.endDateProjectName = " "
        const today = new Date();
        const prevWeek = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000);
        const yesterday = new Date(today.getTime() - 1 * 24 * 60 * 60 * 1000);
        const formattedStartDate = prevWeek.toISOString().split("T")[0];
        const formattedEndDate = yesterday.toISOString().split("T")[0];
        additionalQueryParams = `${additionalQueryParams}&startDate=${formattedStartDate}&endDate=${formattedEndDate}`;
        this.startDateProjectName = formattedStartDate
        this.endDateProjectName = formattedEndDate
      }
      if (this.selectedStartDate && this.selectedEndDate) {
        additionalQueryParams =
          additionalQueryParams + `&startDate=${this.selectedStartDate}&endDate=${this.selectedEndDate}`;
          this.startDateProjectName = " "
        this.endDateProjectName = " "
          this.startDateProjectName = startDate
        this.endDateProjectName = endDate
      }
      this.subCampaignDataAmazon = [];
      let subCategoryIntial = this.selectedKeySubCategoriesType.length === 0 ? "all" : this.selectedKeySubCategoriesType;
      let subSubCategoryIntial = this.selectedKeySubSubCategoriesType.length === 0 ? "all" : this.selectedKeySubSubCategoriesType;
      let subBCGIntial = this.selectedKeyBcg.length === 0 ? "all" : this.selectedKeyBcg;
      let adType = this.selectedKeyAdType.length === 0 ? "all" : this.selectedKeyAdType;
      axios
        .get(
          `${process.env.VUE_APP_SERVER_URL}/api/amazon/get-keyword-report?clientID=${this.client._id}${additionalQueryParams}&sub_category=${subCategoryIntial}&sub_sub_category=${subSubCategoryIntial}&bcg=${subBCGIntial}&ad_type=${adType}`,
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`
            }
          }
        )
        .then(response => {
          this.keywordPlannerData = response.data
          this.isKeywordDataFetched = false;
        })
        .catch(error => {
          this.isKeywordDataFetched = false;
          console.log(error.response.data);
        });
    },
    getSubCampaignReportData(
      dateRange,
      campaignType = null,
      campaigns = null,
      adGroups = null,
      startDate = null,
      endDate = null
    ) {
      this.isSubCampaignReportDataLoading = true;
      if (!dateRange) {
        dateRange = "LAST_7_DAYS";
      }
      this.currentDateRange = dateRange;
      let additionalQueryParams = ``;
      if (!this.selectedStartDate && !this.selectedEndDate) {
        this.startDateProjectName = " "
        this.endDateProjectName = " "
        const today = new Date();
        const prevWeek = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000);
        const yesterday = new Date(today.getTime() - 1 * 24 * 60 * 60 * 1000);
        const formattedStartDate = prevWeek.toISOString().split("T")[0];
        const formattedEndDate = yesterday.toISOString().split("T")[0];
        additionalQueryParams = `${additionalQueryParams}&startDate=${formattedStartDate}&endDate=${formattedEndDate}`;
        this.startDateProjectName = formattedStartDate
        this.endDateProjectName = formattedEndDate
      }
      if (this.selectedStartDate && this.selectedEndDate) {
        additionalQueryParams =
          additionalQueryParams + `&startDate=${this.selectedStartDate}&endDate=${this.selectedEndDate}`;
          this.startDateProjectName = " "
        this.endDateProjectName = " "
          this.startDateProjectName = startDate
        this.endDateProjectName = endDate
      }
      this.subCampaignDataAmazon = [];
      let subCategoryIntial = this.subCategory.length === 0 ? "all" : this.subCategory;
      let subSubCategoryIntial = this.subSubCategory.length === 0 ? "all" : this.subSubCategory;
      let subBCGIntial = this.bcg.length === 0 ? "all" : this.bcg;
      let adType = this.adType.length === 0 ? "all" : this.adType;
      axios
        .get(
          `${process.env.VUE_APP_SERVER_URL}/api/amazon/get-report-filter?clientID=${this.client._id}${additionalQueryParams}&sub_category=${subCategoryIntial}&sub_sub_category=${subSubCategoryIntial}&bcg=${subBCGIntial}&ad_type=${adType}`,
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`
            }
          }
        )
        .then(response => {
          this.subCampaignDataAmazon = response.data
          this.isSubCampaignReportDataLoading = false;
        })
        .catch(error => {
          this.isSubCampaignReportDataLoading = false;
          console.log(error.response.data);
        });
    },
    getSubCatogorySearchViewReportData(
      dateRange,
      campaignType = null,
      campaigns = null,
      adGroups = null,
      startDate = null,
      endDate = null
    ) {
      this.isSubCampaignSearchReportDataLoading = true;
      if (!dateRange) {
        dateRange = "LAST_7_DAYS";
      }
      this.currentDateRange = dateRange;
      let additionalQueryParams = ``;
      if (!this.selectedStartDate && !this.selectedEndDate) {
         this.startDateProjectName = " "
        this.endDateProjectName = " "
        const today = new Date();
        const prevWeek = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000);
        const yesterday = new Date(today.getTime() - 1 * 24 * 60 * 60 * 1000);
        const formattedStartDate = prevWeek.toISOString().split("T")[0];
        const formattedEndDate = yesterday.toISOString().split("T")[0];

        additionalQueryParams = `${additionalQueryParams}&startDate=${formattedStartDate}&endDate=${formattedEndDate}`;
        this.startDateProjectName = formattedStartDate
        this.endDateProjectName = formattedEndDate
      }
      if (this.selectedStartDate && this.selectedEndDate) {
        additionalQueryParams =
          additionalQueryParams + `&startDate=${this.selectedStartDate}&endDate=${this.selectedEndDate}`;
          this.startDateProjectName = " "
        this.endDateProjectName = " "
          this.startDateProjectName = startDate
        this.endDateProjectName = endDate
      }
      this.subSearchCampaignDataAmazon = {};
      let subCategoryIntial = this.subSearchCategory.length === 0 ? "all" : this.subSearchCategory;
      let subSubCategoryIntial = this.subSearchSubCategory.length === 0 ? "all" : this.subSearchSubCategory;
      let subBCGIntial = this.bcgSearch.length === 0 ? "all" : this.bcgSearch;
      let adType = this.adSearchType.length === 0 ? "all" : this.adSearchType;
      axios
        .get(
          `${process.env.VUE_APP_SERVER_URL}/api/amazon/get-subcategory-report?clientID=${this.client._id}${additionalQueryParams}&sub_category=${subCategoryIntial}&sub_sub_category=${subSubCategoryIntial}&bcg=${subBCGIntial}&ad_type=${adType}`,
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`
            }
          }
        )
        .then(response => {
          this.subSearchCampaignDataAmazon = response.data
          this.isSubCampaignSearchReportDataLoading = false;
        })
        .catch(error => {
          this.isSubCampaignSearchReportDataLoading = false;
          console.log(error.response.data);
        });
    },
    getAdTypeSearchViewReportData(
      dateRange,
      campaignType = null,
      campaigns = null,
      adGroups = null,
      startDate = null,
      endDate = null
    ) {
      this.isAdTypeSearchReportDataLoading = true;
      if (!dateRange) {
        dateRange = "LAST_7_DAYS";
      }
      this.currentDateRange = dateRange;
      let additionalQueryParams = ``;
      if (!this.selectedStartDate && !this.selectedEndDate) {
        this.startDateProjectName = " "
        this.endDateProjectName = " "
        const today = new Date();
        const prevWeek = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000);
        const yesterday = new Date(today.getTime() - 1 * 24 * 60 * 60 * 1000);
        const formattedStartDate = prevWeek.toISOString().split("T")[0];
        const formattedEndDate = yesterday.toISOString().split("T")[0];
        additionalQueryParams = `${additionalQueryParams}&startDate=${formattedStartDate}&endDate=${formattedEndDate}`;
        this.startDateProjectName = formattedStartDate
        this.endDateProjectName = formattedEndDate
      }
      if (this.selectedStartDate && this.selectedEndDate) {
        additionalQueryParams =
          additionalQueryParams + `&startDate=${this.selectedStartDate}&endDate=${this.selectedEndDate}`;
          this.startDateProjectName = " "
        this.endDateProjectName = " "
          this.startDateProjectName = startDate
        this.endDateProjectName = endDate
      }
      this.adTypeSearchCampaignDataAmazon = {};
      let subCategoryIntial = this.subSearchCategory.length === 0 ? "all" : this.subSearchCategory;
      let subSubCategoryIntial = this.subSearchSubCategory.length === 0 ? "all" : this.subSearchSubCategory;
      let subBCGIntial = this.bcgSearch.length === 0 ? "all" : this.bcgSearch;
      let adType = this.adSearchType.length === 0 ? "all" : this.adSearchType;
      axios
        .get(
          `${process.env.VUE_APP_SERVER_URL}/api/amazon/get-adtype-report?clientID=${this.client._id}${additionalQueryParams}&sub_category=${subCategoryIntial}&sub_sub_category=${subSubCategoryIntial}&bcg=${subBCGIntial}&ad_type=${adType}`,
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`
            }
          }
        )
        .then(response => {
          this.adTypeSearchCampaignDataAmazon = response.data
          this.isAdTypeSearchReportDataLoading = false;
        })
        .catch(error => {
          this.adTypeSearchCampaignDataAmazon = false;
          console.log(error.response.data);
        });
    },
    getBCGSearchViewReportData(
      dateRange,
      campaignType = null,
      campaigns = null,
      adGroups = null,
      startDate = null,
      endDate = null
    ) {
      this.isBCGSearchReportDataLoading = true;
      if (!dateRange) {
        dateRange = "LAST_7_DAYS";
      }
      this.currentDateRange = dateRange;
      let additionalQueryParams = ``;
      if (!this.selectedStartDate && !this.selectedEndDate) {
        this.startDateProjectName = " "
        this.endDateProjectName = " "
        const today = new Date();
        const prevWeek = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000);
        const yesterday = new Date(today.getTime() - 1 * 24 * 60 * 60 * 1000);
        const formattedStartDate = prevWeek.toISOString().split("T")[0];
        const formattedEndDate = yesterday.toISOString().split("T")[0];
        additionalQueryParams = `${additionalQueryParams}&startDate=${formattedStartDate}&endDate=${formattedEndDate}`;
        this.startDateProjectName = formattedStartDate
        this.endDateProjectName = formattedEndDate
      }
      if (this.selectedStartDate && this.selectedEndDate) {
        additionalQueryParams =
          additionalQueryParams + `&startDate=${this.selectedStartDate}&endDate=${this.selectedEndDate}`;
          this.startDateProjectName = " "
        this.endDateProjectName = " "
          this.startDateProjectName = startDate
        this.endDateProjectName = endDate
      }
      this.bcgSearchCampaignDataAmazon = {};
      let subCategoryIntial = this.subSearchCategory.length === 0 ? "all" : this.subSearchCategory;
      let subSubCategoryIntial = this.subSearchSubCategory.length === 0 ? "all" : this.subSearchSubCategory;
      let subBCGIntial = this.bcgSearch.length === 0 ? "all" : this.bcgSearch;
      let adType = this.adSearchType.length === 0 ? "all" : this.adSearchType;
      axios
        .get(
          `${process.env.VUE_APP_SERVER_URL}/api/amazon/get-bcg-report?clientID=${this.client._id}${additionalQueryParams}&sub_category=${subCategoryIntial}&sub_sub_category=${subSubCategoryIntial}&bcg=${subBCGIntial}&ad_type=${adType}`,
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`
            }
          }
        )
        .then(response => {
          this.bcgSearchCampaignDataAmazon = response.data
          this.isBCGSearchReportDataLoading = false;
        })
        .catch(error => {
          this.isBCGSearchReportDataLoading = false;
          console.log(error.response.data);
        });
    },
    filterReportData(filter) {
      this.getReportData(this.currentDateRange);
    }
  },
  watch:{
    selectedDailySubCategoriesType(newVal, oldVal) {
      this.selectedDailySubCategoriesType = ""
      this.selectedDailySubCategoriesType = newVal
      this.getDailyReportData()
    },
    selectedDailySubSubCategoriesType(newVal, oldVal) {
      this.selectedDailySubSubCategoriesType = ""
      this.selectedDailySubSubCategoriesType = newVal
      this.getDailyReportData()
    },
    selectedDailyBcg(newVal, oldVal) {
      this.selectedDailyBcg = ""
      this.selectedDailyBcg = newVal
      this.getDailyReportData()
    },
    selectedDailyAdType(newVal, oldVal) {
      this.selectedDailyAdType = ""
      this.selectedDailyAdType = newVal
      this.getDailyReportData()
    },

    selectedKeySubCategoriesType(newVal, oldVal) {
      this.selectedKeySubCategoriesType = ""
      this.selectedKeySubCategoriesType = newVal
      this.getKeyWordPlannerReportData()
    },
    selectedKeySubSubCategoriesType(newVal, oldVal) {
      this.selectedKeySubSubCategoriesType = ""
      this.selectedKeySubSubCategoriesType = newVal
      this.getKeyWordPlannerReportData()
    },
    selectedKeyBcg(newVal, oldVal) {
      this.selectedKeyBcg = ""
      this.selectedKeyBcg = newVal
      this.getKeyWordPlannerReportData()
    },
    selectedKeyAdType(newVal, oldVal) {
      this.selectedKeyAdType = ""
      this.selectedKeyAdType = newVal
      this.getKeyWordPlannerReportData()
    },

    subMonthSearchCategory(newVal, oldVal) {
      this.subMonthSearchCategory = ""
      this.subMonthSearchCategory = newVal
      this.getMonthWiseData()
    },
    subMonthSearchSubCategory(newVal, oldVal) {
      this.subMonthSearchSubCategory = ""
      this.subMonthSearchSubCategory = newVal
      this.getMonthWiseData()
    },
    bcgMonthSearch(newVal, oldVal) {
      this.bcgMonthSearch = ""
      this.bcgMonthSearch = newVal
      this.getMonthWiseData()
    },
    adMonthSearchType(newVal, oldVal) {
      this.adMonthSearchType = ""
      this.adMonthSearchType = newVal
      this.getMonthWiseData()
    },

    subSearchCategory(newVal, oldVal) {
      this.subSearchCategory = ""
      this.subSearchCategory = newVal
      this.getSubCatogorySearchViewReportData()
      this.getAdTypeSearchViewReportData();
      this.getBCGSearchViewReportData();
    },
    subSearchSubCategory(newVal, oldVal) {
      this.subSearchSubCategory = ""
      this.subSearchSubCategory = newVal
      this.getSubCatogorySearchViewReportData()
      this.getAdTypeSearchViewReportData();
      this.getBCGSearchViewReportData();
    },
    bcgSearch(newVal, oldVal) {
      this.bcgSearch = ""
      this.bcgSearch = newVal
      this.getSubCatogorySearchViewReportData()
      this.getAdTypeSearchViewReportData();
      this.getBCGSearchViewReportData();
    },
    adSearchType(newVal, oldVal) {
      this.adSearchType = ""
      this.adSearchType = newVal
      this.getSubCatogorySearchViewReportData()
      this.getAdTypeSearchViewReportData();
      this.getBCGSearchViewReportData();
    },
    subCategory(newVal, oldVal) {
      this.subCategory = ""
      this.subCategory = newVal
      this.getSubCampaignReportData()
    },
    subSubCategory(newVal, oldVal) {
      this.subSubCategory = ""
      this.subSubCategory = newVal
      this.getSubCampaignReportData()
    },
    bcg(newVal, oldVal) {
      this.bcg = ""
      this.bcg = newVal
      this.getSubCampaignReportData()
    },
    adType(newVal, oldVal) {
      this.adType = ""
      this.adType = newVal
      this.getSubCampaignReportData()
    },
  },
  mounted() {
    this.tabName = "Graphs";
    this.getClient();
  }
};
</script>

<style lang="scss">
.plannedVsAchieved {
  table {
    width: 100%;
    border-collapse: collapse;
    font-family: Arial, sans-serif;
    margin: 20px 0;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    overflow: hidden;
  }

  th {
    background-color: #200E6B;
    color: white;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    padding: 12px;
    font-size: 14px;
  }

  td {
    padding: 12px;
    font-size: 14px;
    color: #333;
    background-color: #f9f9f9;
  }

  th,
  td {
    border: 1px solid #ddd;
    text-align: center;
  }

  tr:nth-child(even) td {
    background-color: #f2f2f2;
  }

  tr:hover td {
    background-color: #f1f7ff;
    color: #333;
  }
}

.breadcrumb-container a {
  color: antiquewhite !important;
}

.dashboard-overlay .rounded-sm {
  inset: -20px !important;
}
.keyword-planner {
  .card-body {
    padding: 0.6rem !important;
  }
}
.conditional-header {
  display: flex;
  justify-content: space-between;
  margin-top: 0px;
  padding: 0 3px;
  margin-bottom: -20px;
}
input {
  border-radius: 5px;
  padding-left: 8px;
  padding-right: 8px;
  width: 205px;
  height: 38px;
  background-color: white;
  border: 1px solid rgb(218, 211, 211);
  color: rgb(163, 153, 153);
}

.screenAmazonAlignmentMultiChannel {
  margin-left: -0.5rem;
  margin-right: -0.5rem;

  .card-body {
    padding: 0.5rem !important;
  }
}
.multiAmazonchanneldashbpa{
  .nav-tabs .nav-link {
    border: none;
    padding: 4px !important;
    border-radius: 0;
    font-size: 14px;
  }
}
</style>
